import React, { useState } from "react";
import { Popover } from "@shopify/polaris";

function SalesByChannel() {
  const [popoverActive, setPopoverActive] = useState(false);

  const activator = (
    <div className="_Title_1633q_1">
      <h2 className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
        <span className="_WrappedContent_18yn4_29">
          Sales attributed to marketing
        </span>
      </h2>
    </div>
  );

  const textFieldMarkup = (
    <div
      className="Polaris-Box"
      style={{
        "--pc-box-padding-block-start-xs": "var(--p-space-400)",
        "--pc-box-padding-block-end-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
      }}
    >
      <div
        className="Polaris-BlockStack"
        style={{
          "--pc-block-stack-inline-align": "start",
          "--pc-block-stack-order": "column",
          "--pc-block-stack-gap-xs": "var(--p-space-300)",
        }}
      >
        <div
          className="Polaris-BlockStack"
          style={{
            "--pc-block-stack-inline-align": "start",
            "--pc-block-stack-order": "column",
            "--pc-block-stack-gap-xs": "var(--p-space-200)",
          }}
        >
          <p className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--semibold">
            Sales attributed to marketing
          </p>
          <p>
            Value of total sales that can be attributed to traffic driven to
            your online store by trackable marketing efforts.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div
      onMouseEnter={() => setPopoverActive(true)}
      onMouseLeave={() => setPopoverActive(false)}
    >
      <Popover
        active={popoverActive}
        activator={activator}
        preferredAlignment="left"
        autofocusTarget="first-node"
        onClose={() => setPopoverActive(false)}
      >
        <Popover.Pane fixed>
          <div
            style={{
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {textFieldMarkup}
          </div>
        </Popover.Pane>
      </Popover>
    </div>
  );
}

export default SalesByChannel;

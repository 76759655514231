export function formatNumber(num) {
  const suffixes = ["", "K", "M", "B", "T"];
  let formattedNum = num;
  let suffixIndex = 0;

  while (formattedNum >= 1000 && suffixIndex < suffixes.length - 1) {
    formattedNum /= 1000;
    suffixIndex++;
  }

  if (formattedNum < 1 && suffixIndex > 0) {
    formattedNum *= 1000;
    suffixIndex--;
  }

  return Math.floor(formattedNum) + suffixes[suffixIndex];
}

// export function formatNumber(num) {
//   if (num >= 1000) {
//     return (num / 1000)?.toFixed(1) + "K";
//   }
//   return num?.toString();
// }

// export function formatNumber(num) {
//   if (num) {
//     const suffixes = ["", "K", "M", "B", "T"];
//     const numString = num.toString();
//     const numLength = numString.length;
//     const suffixIndex = Math.floor((numLength - 1) / 3);
//     let shortNum = (num / Math.pow(1000, suffixIndex)).toFixed(1);
//     if (shortNum.slice(-1) === "0") {
//       shortNum = shortNum.slice(0, -2); // Remove decimal point and zero
//     }
//     return shortNum + suffixes[suffixIndex];
//   }
// }

import {Button, Popover, ActionList} from '@shopify/polaris';
import {useState, useCallback} from 'react';

function Allchannels() {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      All Channels
    </Button>
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="first-node"
        onClose={togglePopoverActive}
      >
        <ActionList
          actionRole="menuitem"
          items={[{content: 'All Channels'}, {content: 'Online Store'},{content: 'Facebook & Instagram'}, {content: 'Snapchat Ads'}, {content: 'Buy Button'}, {content: 'TikTok'}, {content: 'Other'}]}
        />
      </Popover>
    </div>
  );
}
export default Allchannels
import React from "react";
import DateRangePicker from "../components/analytics/analyticsCalendar";
import AnalyticSales from "../components/home/totalSales";
import SalesByChannel from "../components/analytics/salesByChannels";
import OnlineStore from "../components/home/onlineStore";
import ConversionRate from "../components/home/conversionRate";
import TotalOrders from "../components/home/totalOrders";
import AvergeOrderValue from "../components/analytics/averageOrderValue";
import TopSellingProducts from "../components/analytics/topSellingProducts";
import CohortAnalysis from "../components/analytics/cohortAnalysis";
import ReturningCustomerRate from "../components/analytics/returningCustomerRate";
import SalesAttributeToMarketing from "../components/analytics/salesAttributeToMarketing";
import SessionsByLocations from "../components/analytics/sessionsByLocations";
import SessionsByDevices from "../components/analytics/sessionsByDevices";
import SessionsBySocialSource from "../components/analytics/sessionsBySocialSource";
import SalesBySocialSource from "../components/analytics/salesBySocialSource";
import TopReferrsBySessions from "../components/analytics/topReferrsBySessions";
import SessionsByTrafficSource from "../components/analytics/sessionsByTrafficSource";
import SalesByTrafficSource from "../components/analytics/salesByTrafficSource";
import TopLandingPages from "../components/analytics/topLandingPageBySessions";
import FulFilledOrdersOverTime from "../components/analytics/fulFilledOrdersOverTime";
import CustomerOverTime from "../components/analytics/customerOverTime";
import ProductsBySelfThrough from "../components/analytics/prdouctsBySellThrough";
import { DataTable } from "@shopify/polaris";
import cohortAnalysis from "../assets/chort.PNG";
import { useAnalyticsQuery } from "../slices/spaceApi";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

import {
  BarChart,
  DonutChart,
  LineChart,
  PolarisVizProvider,
  SimpleBarChart,
  StackedAreaChart,
} from "@shopify/polaris-viz";
import { formatNumber } from "../utils/formatNumber";
import { formatReplace } from "../utils/formatReplace";
import { useGlobalContext } from "../context/globalContext";

const columnContentTypes = ["text", "numeric"];

const rowsSelfThrough = [
  [
    <span class="Polaris-DataTable__TooltipContent">
      <a
        href="/store/essentialss-pk/products/8129773109531"
        rel="noopener noreferrer"
        target="_blank"
        data-polaris-unstyled="true"
        class="Polaris-Link"
      >
        Magnetic Wifi Camera | Default Title
      </a>
    </span>,
    "100.00%",
  ],
  [
    <span class="Polaris-DataTable__TooltipContent">
      <a
        href="/store/essentialss-pk/products/8129773109531"
        rel="noopener noreferrer"
        target="_blank"
        data-polaris-unstyled="true"
        class="Polaris-Link"
      >
        60pcs Reusable Waterproof Adhesive Tape | Default Title
      </a>
    </span>,
    "100.00%",
  ],
  [
    <span class="Polaris-DataTable__TooltipContent">
      <a
        href="/store/essentialss-pk/products/8129773109531"
        rel="noopener noreferrer"
        target="_blank"
        data-polaris-unstyled="true"
        class="Polaris-Link"
      >
        Refillable Perfume Bottle | black
      </a>
    </span>,
    "100.00%",
  ],
  [
    <span class="Polaris-DataTable__TooltipContent">
      <a
        href="/store/essentialss-pk/products/8129773109531"
        rel="noopener noreferrer"
        target="_blank"
        data-polaris-unstyled="true"
        class="Polaris-Link"
      >
        Magnetic GPS Tracker (PTA APPROVED) | Default Title
      </a>
    </span>,
    "100.00%",
  ],
  [
    <span class="Polaris-DataTable__TooltipContent">
      <a
        href="/store/essentialss-pk/products/8129773109531"
        rel="noopener noreferrer"
        target="_blank"
        data-polaris-unstyled="true"
        class="Polaris-Link"
      >
        Refillable Perfume Bottle | Default Title
      </a>
    </span>,
    "100.00%",
  ],
];

const formatDateRangeName = (start, end) => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  // If the start and end dates are the same day
  if (startDate.isSame(endDate, "day")) {
    return startDate.format("D MMM YYYY");
  }

  // If the dates are in the same year
  if (startDate.year() === endDate.year()) {
    return `${startDate.format("D MMM")}–${endDate.format("D MMM YYYY")}`;
  }

  // If the dates are in different years
  return `${startDate.format("D MMM YYYY")}–${endDate.format("D MMM YYYY")}`;
};

const Analytics = () => {
  const { analyticDate } = useGlobalContext();

  const startDate = dayjs(analyticDate?.since).format("YYYY-MM-DD");
  const endDate = dayjs(analyticDate?.until).format("YYYY-MM-DD");

  // Define the start and end dates
  const start = dayjs(startDate).startOf("day");
  const end = dayjs(endDate).endOf("day");

  const { data, isLoading } = useAnalyticsQuery(
    {
      start: startDate,
      end: endDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const sortedData = data?.referBySessions
    ? [...data?.referBySessions]?.sort((a, b) => b.value - a.value)
    : [];

  // Map sorted data to the required format
  const rowsReferrers = sortedData?.map((item) => [
    <span className="Polaris-DataTable__TooltipContent">
      {item.referBySession}
    </span>,
    item.value,
  ]);

  const sortedDataLandingPage = data?.landingPageBySessions
    ? [...data?.landingPageBySessions]?.sort((a, b) => b.value - a.value)
    : [];

  const landingPageRows = sortedDataLandingPage?.map((item) => [
    <span className="Polaris-DataTable__TooltipContent">
      <a
        href={`https://essentialss.co${item.landingPageBySession}`}
        rel="noopener noreferrer"
        target="_blank"
        data-polaris-unstyled="true"
        className="Polaris-Link"
      >
        {item.landingPageBySession}
      </a>
    </span>,
    item.value,
  ]);

  console.log(data);

  const rows = data?.sessionsRate
    ? [
        [
          <span className="Polaris-DataTable__TooltipContent">
            <div className="Polaris-Box">
              <p className="Polaris-Text--root Polaris-Text--block Polaris-Text--truncate">
                Added to cart
              </p>
              <p className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--subdued Polaris-Text--truncate">
                {data?.sessionsRate?.addedToCart.totalSessions} sessions
              </p>
            </div>
          </span>,
          `${data?.sessionsRate?.addedToCart.conversionRate.toFixed(2)}%`,
        ],
        [
          <span className="Polaris-DataTable__TooltipContent">
            <div className="Polaris-Box">
              <p className="Polaris-Text--root Polaris-Text--block Polaris-Text--truncate">
                Reached checkout
              </p>
              <p className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--subdued Polaris-Text--truncate">
                {data?.sessionsRate?.reachedCheckout.totalSessions} sessions
              </p>
            </div>
          </span>,
          `${data?.sessionsRate?.reachedCheckout.conversionRate.toFixed(2)}%`,
        ],
        [
          <span className="Polaris-DataTable__TooltipContent">
            <div className="Polaris-Box">
              <p className="Polaris-Text--root Polaris-Text--block Polaris-Text--truncate">
                Sessions converted
              </p>
              <p className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--subdued Polaris-Text--truncate">
                {data?.sessionsRate?.sessionsConverted.totalSessions} sessions
              </p>
            </div>
          </span>,
          `${data?.sessionsRate?.sessionsConverted.conversionRate.toFixed(2)}%`,
        ],
      ]
    : [];

  // Calculate the difference in days
  const diffInDays = end.diff(start, "day") + 1; // Add 1 to include both start and end dates

  // Calculate the previous start and end dates
  const prevStart = start.subtract(diffInDays, "day");
  const prevEnd = prevStart.add(diffInDays - 1, "day");

  const durationInDays = dayjs(endDate).diff(dayjs(startDate), "day");
  let intervalFormat;
  if (durationInDays <= 3) {
    intervalFormat = "%Y-%m-%d %H"; // Hourly
  } else if (durationInDays <= 90) {
    intervalFormat = "%Y-%m-%d"; // Daily
  } else {
    intervalFormat = "%Y-%m"; // Monthly
  }

  const name = formatDateRangeName(start, end);

  const formattedSalesData = data?.sales?.salesChange?.map((item) => ({
    key: item._id,
    value: item.totalSales?.toFixed(2),
  }));

  const salesChartData = formattedSalesData
    ? [
        {
          name,
          data: formattedSalesData,
        },
      ]
    : [];

  const formattedSessionsData = data?.sessions?.sessionsChange?.map((item) => ({
    key: item._id,
    value: item.totalSessions,
  }));

  const sessionsChartData = formattedSalesData
    ? [
        {
          name,
          data: formattedSessionsData,
        },
      ]
    : [];

  const formattedOrdersData = data?.orders?.ordersChange?.map((item) => ({
    key: item._id,
    value: item.totalOrders,
  }));

  const ordersChartData = formattedOrdersData
    ? [
        {
          name,
          data: formattedOrdersData,
        },
      ]
    : [];

  const formattedAverageOrderValueData =
    data?.avgOrderValue?.totalAvgOrderValueChange?.map((item) => ({
      key: item._id,
      value: item.avgOrderValue?.toFixed(2),
    }));

  const averageOrderValueChartData = formattedAverageOrderValueData
    ? [
        {
          name,
          data: formattedAverageOrderValueData,
        },
      ]
    : [];

  const formattedfulFilledOrdersData =
    data?.fulFilledOrders?.fulFilledOrdersChangeTime?.map((item) => ({
      key: item._id,
      value: item.totalOrders,
    }));

  const fulFilledOrdersChartData = formattedfulFilledOrdersData
    ? [
        {
          name,
          data: formattedfulFilledOrdersData,
        },
      ]
    : [];

  const sortedSalesByChannel = data?.salesByChannel
    ? [...data?.salesByChannel]?.sort((a, b) => b.value - a.value)
    : [];

  const salesByChannelsChartData = sortedSalesByChannel?.map((item) => ({
    key: item._id,
    value: parseFloat(item.value.toFixed(2)),
  }));

  const sortedTopSellingProducts = data?.topSellingProducts
    ? [...data?.topSellingProducts]?.sort((a, b) => b.value - a.value)
    : [];

  const topSellingProductsChartData = sortedTopSellingProducts?.map((item) => ({
    key: item._id,
    value: parseFloat(item.value.toFixed(2)),
  }));

  const sortedSessionsByLocations = data?.sessionsByLocations
    ? [...data?.sessionsByLocations]?.sort((a, b) => b.value - a.value)
    : [];

  const sessionsByLocationsChartData = sortedSessionsByLocations?.map(
    (item) => ({
      key: item._id,
      value: parseFloat(item.value.toFixed(2)),
    })
  );

  const sortedSessionsBySocialSource = data?.sessionsBySocialSource
    ? [...data?.sessionsBySocialSource]?.sort((a, b) => b.value - a.value)
    : [];

  const sessionsBySocialSourceChartData = sortedSessionsBySocialSource?.map(
    (item) => ({
      key: item._id,
      value: parseFloat(item.value.toFixed(2)),
    })
  );

  const sortedSessionsByTrafficSource = data?.sessionsByTrafficSource
    ? [...data?.sessionsByTrafficSource]?.sort((a, b) => b.value - a.value)
    : [];

  const sessionsByTrafficSourceChartData = sortedSessionsByTrafficSource?.map(
    (item) => ({
      key: item._id,
      value: parseFloat(item.value.toFixed(2)),
    })
  );

  const sortedSalesByTrafficSource = data?.salesByTrafficeSource
    ? [...data?.salesByTrafficeSource]?.sort((a, b) => b.value - a.value)
    : [];

  const salesByTrafficSourceChartData = sortedSalesByTrafficSource?.map(
    (item) => ({
      key: item._id,
      value: parseFloat(item.value.toFixed(2)),
    })
  );

  const sortedSalesBySocialSource = data?.salesBySocialSource
    ? [...data?.salesBySocialSource]?.sort((a, b) => b.value - a.value)
    : [];

  const salesBySocialSourceChartDataDUmmy = sortedSalesBySocialSource?.map(
    (item) => ({
      key: item._id,
      value: parseFloat(item.value.toFixed(2)),
    })
  );

  let salesBySocialSourceChartData = salesBySocialSourceChartDataDUmmy
    ? [...salesBySocialSourceChartDataDUmmy]
    : [];

  // Remove the last element from the copied array
  salesBySocialSourceChartData.pop();

  const donutChartData = data?.sessionsByDevices.map((device) => ({
    name: device.name,
    data: [
      {
        key: device.name,
        value: device.data[0].value,
      },
    ],
  }));

  const transformData = (data) => {
    const firstTimeData = data?.map((item) => ({
      key: item.date,
      value: item.totalFirstTime,
    }));

    const returningData = data?.map((item) => ({
      key: item.date,
      value: item.totalReturning,
    }));

    return [
      {
        name: "First time",
        data: firstTimeData,
      },
      {
        name: "Returning",
        data: returningData,
      },
    ];
  };

  const returningChartData = data?.returningCustomerRate?.[0]?.data
    ? transformData(data?.returningCustomerRate?.[0]?.data)
    : [];

  return (
    <>
      <Helmet>
        <title>essentailss · Analytics · Shopify</title>
      </Helmet>

      <div className="_OverviewDashboard_o2wc9_9">
        <div
          className="Polaris-InlineGrid"
          style={{
            "--pc-inline-grid-grid-template-columns-xs":
              "repeat(1, minmax(0, 1fr))",
          }}
        >
          <div class="Polaris-Page Polaris-Page--fullWidth">
            <div
              className="Polaris-Box"
              style={{
                "--pc-box-padding-block-start-xs": "var(--p-space-400)",
                "--pc-box-padding-block-start-md": "var(--p-space-600)",
                "--pc-box-padding-block-end-xs": "var(--p-space-400)",
                "--pc-box-padding-block-end-md": "var(--p-space-600)",
                "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
                "--pc-box-padding-inline-start-sm": "var(--p-space-0)",
                "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
                "--pc-box-padding-inline-end-sm": "var(--p-space-0)",
                position: "relative",
              }}
            >
              <div role="status">
                <p className="Polaris-Text--root Polaris-Text--visuallyHidden">
                  Analytics. This page is ready
                </p>
              </div>
              <div className="Polaris-Page-Header--noBreadcrumbs Polaris-Page-Header--mediumTitle">
                <div className="Polaris-Page-Header__Row">
                  <div className="Polaris-Page-Header__TitleWrapper">
                    <div className="Polaris-Header-Title__TitleWrapper">
                      <h1 className="Polaris-Header-Title">
                        <span className="Polaris-Text--root Polaris-Text--headingLg Polaris-Text--bold">
                          Analytics
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div className="Polaris-Page-Header__RightAlign">
                    <div className="Polaris-ActionMenu">
                      <div className="Polaris-ActionMenu-Actions__ActionsLayoutOuter">
                        <div className="Polaris-ActionMenu-Actions__ActionsLayoutMeasurer">
                          <div className="Polaris-ActionMenu-SecondaryAction">
                            <button
                              className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconWithText"
                              type="button"
                            >
                              <span className="Polaris-Button__Icon">
                                <span className="Polaris-Icon">
                                  <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg"
                                    focusable="false"
                                    aria-hidden="true"
                                  >
                                    <path d="M12.75 3.5a.75.75 0 0 0 0 1.5h1.19l-3.22 3.22a.75.75 0 0 0 1.06 1.06l3.22-3.22v1.19a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-.75-.75h-3Z"></path>
                                    <path d="M7.25 16.5a.75.75 0 0 0 0-1.5h-1.19l3.22-3.22a.75.75 0 1 0-1.06-1.06l-3.22 3.22v-1.19a.75.75 0 0 0-1.5 0v3c0 .414.336.75.75.75h3Z"></path>
                                  </svg>
                                </span>
                              </span>
                              <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                Fullscreen
                              </span>
                            </button>
                          </div>
                          <div className="Polaris-ActionMenu-SecondaryAction">
                            <button
                              className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--disclosure"
                              type="button"
                            >
                              <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                More actions
                              </span>
                              <span className="Polaris-Button__Icon">
                                <span className="Polaris-Icon">
                                  <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg"
                                    focusable="false"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                                    ></path>
                                  </svg>
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="Polaris-ActionMenu-Actions__ActionsLayout">
                          <div className="Polaris-ActionMenu-SecondaryAction">
                            <button
                              className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconWithText"
                              type="button"
                            >
                              <span className="Polaris-Button__Icon">
                                <span className="Polaris-Icon">
                                  <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg"
                                    focusable="false"
                                    aria-hidden="true"
                                  >
                                    <path d="M12.75 3.5a.75.75 0 0 0 0 1.5h1.19l-3.22 3.22a.75.75 0 0 0 1.06 1.06l3.22-3.22v1.19a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-.75-.75h-3Z"></path>
                                    <path d="M7.25 16.5a.75.75 0 0 0 0-1.5h-1.19l3.22-3.22a.75.75 0 1 0-1.06-1.06l-3.22 3.22v-1.19a.75.75 0 0 0-1.5 0v3c0 .414.336.75.75.75h3Z"></path>
                                  </svg>
                                </span>
                              </span>
                              <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                Fullscreen
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div className="_HeaderActions_o2wc9_21">
                {/* InlineStack 1 */}
                <div
                  className="Polaris-InlineStack"
                  style={{
                    "--pc-inline-stack-wrap": "nowrap",
                    "--pc-inline-stack-gap-xs": "var(--p-space-200)",
                    "--pc-inline-stack-flex-direction-xs": "row",
                  }}
                >
                  <div>
                    <div className="_Wrapper_10f24_1">
                      <DateRangePicker />
                    </div>
                  </div>
                  <div>
                    <div className="_Wrapper_10f24_1">
                      <button
                        className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter"
                        type="button"
                        tabIndex="0"
                        aria-controls=":rfp:"
                        aria-owns=":rfp:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                          No comparison
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* InlineStack 2 */}
                <div
                  className="Polaris-InlineStack"
                  style={{
                    "--pc-inline-stack-block-align": "center",
                    "--pc-inline-stack-wrap": "wrap",
                    "--pc-inline-stack-gap-xs": "var(--p-space-300)",
                    "--pc-inline-stack-flex-direction-xs": "row",
                  }}
                >
                  <label
                    className="Polaris-Choice Polaris-Choice--disabled Polaris-Checkbox__ChoiceLabel"
                    htmlFor=":rfq:"
                  >
                    <span className="Polaris-Choice__Control">
                      <span className="Polaris-Checkbox">
                        <input
                          id=":rfq:"
                          type="checkbox"
                          disabled
                          className="Polaris-Checkbox__Input"
                          aria-invalid="false"
                          role="checkbox"
                          aria-checked="false"
                          value="overview-dashboard-auto-refresh-data"
                        />
                        <span className="Polaris-Checkbox__Backdrop"></span>
                        <span className="Polaris-Checkbox__Icon Polaris-Checkbox--animated">
                          <svg
                            viewBox="0 0 16 16"
                            shapeRendering="geometricPrecision"
                            textRendering="geometricPrecision"
                          >
                            <path
                              className=""
                              d="M1.5,5.5L3.44655,8.22517C3.72862,8.62007,4.30578,8.64717,4.62362,8.28044L10.5,1.5"
                              transform="translate(2 2.980376)"
                              opacity="0"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              pathLength="1"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </span>
                    <span className="Polaris-Choice__Label">
                      <span className="Polaris-Text--root Polaris-Text--bodyMd">
                        <span
                          className=""
                          tabIndex="0"
                          aria-describedby=":rfr:"
                          data-polaris-tooltip-activator="true"
                        >
                          <span className="_Text_8d88o_1">Auto-refresh</span>
                        </span>
                      </span>
                    </span>
                  </label>

                  <div className="Polaris-Box" style={{ position: "relative" }}>
                    <button
                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter"
                      type="button"
                    >
                      <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                        Customize
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="_DroppableContainer_5geio_1"
                style={{ minHeight: "78vh" }}
              >
                <div
                  className="Polaris-InlineGrid"
                  style={{
                    "--pc-inline-grid-grid-template-columns-xs":
                      "repeat(1, minmax(0, 1fr))",
                    "--pc-inline-grid-grid-template-columns-sm":
                      "repeat(1, minmax(0, 1fr))",
                    "--pc-inline-grid-grid-template-columns-md":
                      "repeat(1, minmax(0, 1fr))",
                    "--pc-inline-grid-grid-template-columns-lg":
                      "repeat(2, minmax(0, 1fr))",
                    "--pc-inline-grid-grid-template-columns-xl":
                      "repeat(3, minmax(0, 1fr))",
                    "--pc-inline-grid-gap-xs": "var(--p-space-400)",
                  }}
                >
                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="total-sales"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sales_over_time?since=2021-06-15&amp;until=2024-06-15&amp;over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <AnalyticSales />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Total sales report"
                                      href="/store/essentialss-pk/reports/sales_over_time?since=2021-06-15&amp;until=2024-06-15&amp;over=month"
                                      tabIndex="0"
                                      aria-describedby=":rl6:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fill-rule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          Rs&nbsp;
                                          {data?.sales?.totalSales
                                            ?.toFixed(2)
                                            ?.replace(formatReplace, ",")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {salesChartData?.length >= 1 && (
                                      <PolarisVizProvider themes={{}}>
                                        <LineChart
                                          theme="default"
                                          data={salesChartData}
                                          yAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return `${"PKR"}${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          xAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return intervalFormat ===
                                                "%Y-%m-%d %H"
                                                ? dayjs(value).format("hh:mm A")
                                                : intervalFormat === "%Y-%m-%d"
                                                ? dayjs(value).format("DD MMM")
                                                : dayjs(value).format(
                                                    "MMM YYYY"
                                                  );
                                            },
                                          }}
                                          tooltipOptions={{
                                            renderTooltipContent: (data) => (
                                              <div
                                                class="Polaris-Box"
                                                style={{
                                                  "--pc-box-background":
                                                    "var(--p-color-bg-surface)",
                                                  "--pc-box-border-radius":
                                                    "var(--p-border-radius-200)",
                                                  "--pc-box-min-width": "150px",
                                                  "--pc-box-padding-block-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-block-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-shadow":
                                                    "var(--p-shadow-300)",
                                                }}
                                              >
                                                <div
                                                  class="Polaris-BlockStack"
                                                  style={{
                                                    "--pc-block-stack-order":
                                                      "column",
                                                    "--pc-block-stack-gap-xs":
                                                      "var(--p-space-100)",
                                                  }}
                                                >
                                                  <div
                                                    class="Polaris-BlockStack"
                                                    style={{
                                                      "--pc-block-stack-order":
                                                        "column",
                                                      "--pc-block-stack-gap-xs":
                                                        "var(--p-space-200)",
                                                    }}
                                                  >
                                                    <div
                                                      class="Polaris-Box"
                                                      style={{ opacity: 1 }}
                                                    >
                                                      <div
                                                        class="Polaris-InlineStack"
                                                        style={{
                                                          "--pc-inline-stack-align":
                                                            "space-between",
                                                          "--pc-inline-stack-block-align":
                                                            "center",
                                                          "--pc-inline-stack-wrap":
                                                            "nowrap",
                                                          "--pc-inline-stack-gap-xs":
                                                            "var(--p-space-300)",
                                                          "--pc-inline-stack-flex-direction-xs":
                                                            "row",
                                                        }}
                                                      >
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          s
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-100)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div class="Polaris-Box">
                                                            <span
                                                              class="_Container_8b5v1_1"
                                                              style={{
                                                                height: "2px",
                                                                width: "12px",
                                                              }}
                                                            >
                                                              <svg
                                                                class="_SVG_8b5v1_5"
                                                                height="2px"
                                                                viewBox="0 0 12 2"
                                                                width="12px"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <defs>
                                                                  <linearGradient
                                                                    id="linePreviewGradient-40"
                                                                    x1="0%"
                                                                    x2="100%"
                                                                    y1="0%"
                                                                    y2="0%"
                                                                    gradientUnits="userSpaceOnUse"
                                                                  >
                                                                    <stop
                                                                      offset="0%"
                                                                      stop-color="#0A97D5"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                    <stop
                                                                      offset="100%"
                                                                      stop-color="#50C3F7"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                  </linearGradient>
                                                                </defs>
                                                                <path
                                                                  d="M1 1 H12"
                                                                  stroke="url(#linePreviewGradient-40)"
                                                                  stroke-linejoin="round"
                                                                  stroke-linecap="round"
                                                                  stroke-width="2"
                                                                  stroke-dasharray="none"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </div>
                                                          <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                                                            {intervalFormat ===
                                                            "%Y-%m-%d %H"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                )?.format(
                                                                  "DD MMM, hh:mm A"
                                                                )
                                                              : intervalFormat ===
                                                                "%Y-%m-%d"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "DD-MMM-YYYY"
                                                                )
                                                              : dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "MMM YYYY"
                                                                )}
                                                          </p>
                                                        </div>
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-200)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div
                                                            class="_Value_18pd5_1"
                                                            style={{
                                                              marginLeft: "0px",
                                                            }}
                                                          >
                                                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                                              {data?.dataSeries?.[0]?.data?.[
                                                                data
                                                                  ?.activeIndex
                                                              ]?.value?.replace(
                                                                formatReplace,
                                                                ","
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ),
                                          }}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sales-channels"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sales_by_channel_name?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SalesByChannel />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sales by channel report"
                                      href="/store/essentialss-pk/reports/sales_by_channel_name?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex={0}
                                      aria-describedby=":rl9:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {salesByChannelsChartData?.length >= 1 && (
                                      <SimpleBarChart
                                        data={[
                                          {
                                            data: salesByChannelsChartData,
                                            name: "Sales by traffic source",
                                          },
                                        ]}
                                        xAxisOptions={{
                                          // labelFormatter: (value) =>
                                          //   `PKR${formatNumber(value)}`, // Formatting labels if needed
                                          labelFormatter: (value) => {
                                            return `${"PKR"}${
                                              value ? formatNumber(value) : 0
                                            }`;
                                          },
                                        }}
                                        yAxisOptions={{
                                          labelFormatter: (value) =>
                                            `${value.toLocaleString()}`, // Format y-axis labels as currency
                                        }}
                                        showLegend={false}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sessions"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sessions_over_time?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <OnlineStore />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Online store sessions report"
                                      href="/store/essentialss-pk/reports/sessions_over_time?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rmm:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fill-rule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          {data?.sessions?.totalSessions?.toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {sessionsChartData?.length >= 1 && (
                                      <PolarisVizProvider themes={{}}>
                                        <LineChart
                                          theme="default"
                                          data={sessionsChartData}
                                          yAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return `${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          xAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return intervalFormat ===
                                                "%Y-%m-%d %H"
                                                ? dayjs(value).format("hh:mm A")
                                                : intervalFormat === "%Y-%m-%d"
                                                ? dayjs(value).format("DD MMM")
                                                : dayjs(value).format(
                                                    "MMM YYYY"
                                                  );
                                            },
                                          }}
                                          tooltipOptions={{
                                            renderTooltipContent: (data) => (
                                              <div
                                                class="Polaris-Box"
                                                style={{
                                                  "--pc-box-background":
                                                    "var(--p-color-bg-surface)",
                                                  "--pc-box-border-radius":
                                                    "var(--p-border-radius-200)",
                                                  "--pc-box-min-width": "150px",
                                                  "--pc-box-padding-block-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-block-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-shadow":
                                                    "var(--p-shadow-300)",
                                                }}
                                              >
                                                <div
                                                  class="Polaris-BlockStack"
                                                  style={{
                                                    "--pc-block-stack-order":
                                                      "column",
                                                    "--pc-block-stack-gap-xs":
                                                      "var(--p-space-100)",
                                                  }}
                                                >
                                                  <div
                                                    class="Polaris-BlockStack"
                                                    style={{
                                                      "--pc-block-stack-order":
                                                        "column",
                                                      "--pc-block-stack-gap-xs":
                                                        "var(--p-space-200)",
                                                    }}
                                                  >
                                                    <div
                                                      class="Polaris-Box"
                                                      style={{ opacity: 1 }}
                                                    >
                                                      <div
                                                        class="Polaris-InlineStack"
                                                        style={{
                                                          "--pc-inline-stack-align":
                                                            "space-between",
                                                          "--pc-inline-stack-block-align":
                                                            "center",
                                                          "--pc-inline-stack-wrap":
                                                            "nowrap",
                                                          "--pc-inline-stack-gap-xs":
                                                            "var(--p-space-300)",
                                                          "--pc-inline-stack-flex-direction-xs":
                                                            "row",
                                                        }}
                                                      >
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          s
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-100)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div class="Polaris-Box">
                                                            <span
                                                              class="_Container_8b5v1_1"
                                                              style={{
                                                                height: "2px",
                                                                width: "12px",
                                                              }}
                                                            >
                                                              <svg
                                                                class="_SVG_8b5v1_5"
                                                                height="2px"
                                                                viewBox="0 0 12 2"
                                                                width="12px"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <defs>
                                                                  <linearGradient
                                                                    id="linePreviewGradient-40"
                                                                    x1="0%"
                                                                    x2="100%"
                                                                    y1="0%"
                                                                    y2="0%"
                                                                    gradientUnits="userSpaceOnUse"
                                                                  >
                                                                    <stop
                                                                      offset="0%"
                                                                      stop-color="#0A97D5"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                    <stop
                                                                      offset="100%"
                                                                      stop-color="#50C3F7"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                  </linearGradient>
                                                                </defs>
                                                                <path
                                                                  d="M1 1 H12"
                                                                  stroke="url(#linePreviewGradient-40)"
                                                                  stroke-linejoin="round"
                                                                  stroke-linecap="round"
                                                                  stroke-width="2"
                                                                  stroke-dasharray="none"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </div>
                                                          <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                                                            {intervalFormat ===
                                                            "%Y-%m-%d %H"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                )?.format(
                                                                  "DD MMM, hh:mm A"
                                                                )
                                                              : intervalFormat ===
                                                                "%Y-%m-%d"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "DD-MMM-YYYY"
                                                                )
                                                              : dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "MMM YYYY"
                                                                )}
                                                          </p>
                                                        </div>
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-200)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div
                                                            class="_Value_18pd5_1"
                                                            style={{
                                                              marginLeft: "0px",
                                                            }}
                                                          >
                                                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                                              {data?.dataSeries?.[0]?.data?.[
                                                                data
                                                                  ?.activeIndex
                                                              ]?.toLocaleString()}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ),
                                          }}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="conversion-rate"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/online_store_conversion?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <ConversionRate analytics={true} />
                                      </div>
                                    </div>
                                  </a>
                                </div>

                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Online store conversion rate report"
                                      href="/store/essentialss-pk/reports/online_store_conversion?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rmp:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>

                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          {data?.sessionsRate?.conversionRate?.toFixed(
                                            2
                                          )}
                                          %
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div
                                      className="Polaris-Bleed"
                                      style={{
                                        "--pc-bleed-margin-inline-start-xs":
                                          "var(--p-space-400)",
                                        "--pc-bleed-margin-inline-end-xs":
                                          "var(--p-space-400)",
                                      }}
                                    >
                                      <div className="">
                                        <DataTable
                                          columnContentTypes={
                                            columnContentTypes
                                          }
                                          headings={[]}
                                          rows={rows}
                                          truncate
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="total-orders"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/orders_over_time?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <TotalOrders />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Total orders report"
                                      href="/store/essentialss-pk/reports/orders_over_time?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rlc:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          {data?.orders?.totalOrders?.toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {ordersChartData?.length >= 1 && (
                                      <PolarisVizProvider themes={{}}>
                                        <LineChart
                                          theme="default"
                                          data={ordersChartData}
                                          yAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return `${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          xAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return intervalFormat ===
                                                "%Y-%m-%d %H"
                                                ? dayjs(value).format("hh:mm A")
                                                : intervalFormat === "%Y-%m-%d"
                                                ? dayjs(value).format("DD MMM")
                                                : dayjs(value).format(
                                                    "MMM YYYY"
                                                  );
                                            },
                                          }}
                                          tooltipOptions={{
                                            renderTooltipContent: (data) => (
                                              <div
                                                class="Polaris-Box"
                                                style={{
                                                  "--pc-box-background":
                                                    "var(--p-color-bg-surface)",
                                                  "--pc-box-border-radius":
                                                    "var(--p-border-radius-200)",
                                                  "--pc-box-min-width": "150px",
                                                  "--pc-box-padding-block-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-block-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-shadow":
                                                    "var(--p-shadow-300)",
                                                }}
                                              >
                                                <div
                                                  class="Polaris-BlockStack"
                                                  style={{
                                                    "--pc-block-stack-order":
                                                      "column",
                                                    "--pc-block-stack-gap-xs":
                                                      "var(--p-space-100)",
                                                  }}
                                                >
                                                  <div
                                                    class="Polaris-BlockStack"
                                                    style={{
                                                      "--pc-block-stack-order":
                                                        "column",
                                                      "--pc-block-stack-gap-xs":
                                                        "var(--p-space-200)",
                                                    }}
                                                  >
                                                    <div
                                                      class="Polaris-Box"
                                                      style={{ opacity: 1 }}
                                                    >
                                                      <div
                                                        class="Polaris-InlineStack"
                                                        style={{
                                                          "--pc-inline-stack-align":
                                                            "space-between",
                                                          "--pc-inline-stack-block-align":
                                                            "center",
                                                          "--pc-inline-stack-wrap":
                                                            "nowrap",
                                                          "--pc-inline-stack-gap-xs":
                                                            "var(--p-space-300)",
                                                          "--pc-inline-stack-flex-direction-xs":
                                                            "row",
                                                        }}
                                                      >
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          s
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-100)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div class="Polaris-Box">
                                                            <span
                                                              class="_Container_8b5v1_1"
                                                              style={{
                                                                height: "2px",
                                                                width: "12px",
                                                              }}
                                                            >
                                                              <svg
                                                                class="_SVG_8b5v1_5"
                                                                height="2px"
                                                                viewBox="0 0 12 2"
                                                                width="12px"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <defs>
                                                                  <linearGradient
                                                                    id="linePreviewGradient-40"
                                                                    x1="0%"
                                                                    x2="100%"
                                                                    y1="0%"
                                                                    y2="0%"
                                                                    gradientUnits="userSpaceOnUse"
                                                                  >
                                                                    <stop
                                                                      offset="0%"
                                                                      stop-color="#0A97D5"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                    <stop
                                                                      offset="100%"
                                                                      stop-color="#50C3F7"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                  </linearGradient>
                                                                </defs>
                                                                <path
                                                                  d="M1 1 H12"
                                                                  stroke="url(#linePreviewGradient-40)"
                                                                  stroke-linejoin="round"
                                                                  stroke-linecap="round"
                                                                  stroke-width="2"
                                                                  stroke-dasharray="none"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </div>
                                                          <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                                                            {intervalFormat ===
                                                            "%Y-%m-%d %H"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                )?.format(
                                                                  "DD MMM, hh:mm A"
                                                                )
                                                              : intervalFormat ===
                                                                "%Y-%m-%d"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "DD-MMM-YYYY"
                                                                )
                                                              : dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "MMM YYYY"
                                                                )}
                                                          </p>
                                                        </div>
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-200)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div
                                                            class="_Value_18pd5_1"
                                                            style={{
                                                              marginLeft: "0px",
                                                            }}
                                                          >
                                                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                                              {data?.dataSeries?.[0]?.data?.[
                                                                data
                                                                  ?.activeIndex
                                                              ]?.value?.toLocaleString()}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ),
                                          }}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    aria-disabled={true}
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="average-order-value"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled={true}
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/average_order_value?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <AvergeOrderValue />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled={true}
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Average order value report"
                                      href="/store/essentialss-pk/reports/average_order_value?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex={0}
                                      aria-describedby=":rlf:"
                                      data-polaris-tooltip-activator={true}
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable={false}
                                            aria-hidden={true}
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          Rs&nbsp;
                                          {data?.avgOrderValue?.totalAvgOrderValue
                                            ?.toFixed(2)
                                            ?.replace(formatReplace, ",")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {averageOrderValueChartData?.length >=
                                      1 && (
                                      <PolarisVizProvider themes={{}}>
                                        <LineChart
                                          theme="default"
                                          data={averageOrderValueChartData}
                                          yAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return `${"PKR"}${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          xAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return intervalFormat ===
                                                "%Y-%m-%d %H"
                                                ? dayjs(value).format("hh:mm A")
                                                : intervalFormat === "%Y-%m-%d"
                                                ? dayjs(value).format("DD MMM")
                                                : dayjs(value).format(
                                                    "MMM YYYY"
                                                  );
                                            },
                                          }}
                                          tooltipOptions={{
                                            renderTooltipContent: (data) => (
                                              <div
                                                class="Polaris-Box"
                                                style={{
                                                  "--pc-box-background":
                                                    "var(--p-color-bg-surface)",
                                                  "--pc-box-border-radius":
                                                    "var(--p-border-radius-200)",
                                                  "--pc-box-min-width": "150px",
                                                  "--pc-box-padding-block-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-block-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-shadow":
                                                    "var(--p-shadow-300)",
                                                }}
                                              >
                                                <div
                                                  class="Polaris-BlockStack"
                                                  style={{
                                                    "--pc-block-stack-order":
                                                      "column",
                                                    "--pc-block-stack-gap-xs":
                                                      "var(--p-space-100)",
                                                  }}
                                                >
                                                  <div
                                                    class="Polaris-BlockStack"
                                                    style={{
                                                      "--pc-block-stack-order":
                                                        "column",
                                                      "--pc-block-stack-gap-xs":
                                                        "var(--p-space-200)",
                                                    }}
                                                  >
                                                    <div
                                                      class="Polaris-Box"
                                                      style={{ opacity: 1 }}
                                                    >
                                                      <div
                                                        class="Polaris-InlineStack"
                                                        style={{
                                                          "--pc-inline-stack-align":
                                                            "space-between",
                                                          "--pc-inline-stack-block-align":
                                                            "center",
                                                          "--pc-inline-stack-wrap":
                                                            "nowrap",
                                                          "--pc-inline-stack-gap-xs":
                                                            "var(--p-space-300)",
                                                          "--pc-inline-stack-flex-direction-xs":
                                                            "row",
                                                        }}
                                                      >
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          s
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-100)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div class="Polaris-Box">
                                                            <span
                                                              class="_Container_8b5v1_1"
                                                              style={{
                                                                height: "2px",
                                                                width: "12px",
                                                              }}
                                                            >
                                                              <svg
                                                                class="_SVG_8b5v1_5"
                                                                height="2px"
                                                                viewBox="0 0 12 2"
                                                                width="12px"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <defs>
                                                                  <linearGradient
                                                                    id="linePreviewGradient-40"
                                                                    x1="0%"
                                                                    x2="100%"
                                                                    y1="0%"
                                                                    y2="0%"
                                                                    gradientUnits="userSpaceOnUse"
                                                                  >
                                                                    <stop
                                                                      offset="0%"
                                                                      stop-color="#0A97D5"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                    <stop
                                                                      offset="100%"
                                                                      stop-color="#50C3F7"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                  </linearGradient>
                                                                </defs>
                                                                <path
                                                                  d="M1 1 H12"
                                                                  stroke="url(#linePreviewGradient-40)"
                                                                  stroke-linejoin="round"
                                                                  stroke-linecap="round"
                                                                  stroke-width="2"
                                                                  stroke-dasharray="none"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </div>
                                                          <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                                                            {intervalFormat ===
                                                            "%Y-%m-%d %H"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                )?.format(
                                                                  "DD MMM, hh:mm A"
                                                                )
                                                              : intervalFormat ===
                                                                "%Y-%m-%d"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "DD-MMM-YYYY"
                                                                )
                                                              : dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "MMM YYYY"
                                                                )}
                                                          </p>
                                                        </div>
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-200)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div
                                                            class="_Value_18pd5_1"
                                                            style={{
                                                              marginLeft: "0px",
                                                            }}
                                                          >
                                                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                                              {data?.dataSeries?.[0]?.data?.[
                                                                data
                                                                  ?.activeIndex
                                                              ]?.value?.replace(
                                                                formatReplace,
                                                                ","
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ),
                                          }}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="top-products-by-units-sold"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sales_by_product_name?since=2021-06-15&until=2024-06-15&over=month&sort=net_quantity,DESC"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <TopSellingProducts />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Top selling products report"
                                      href="/store/essentialss-pk/reports/sales_by_product_name?since=2021-06-15&until=2024-06-15&over=month&sort=net_quantity,DESC"
                                      tabIndex="0"
                                      aria-describedby=":rli:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {topSellingProductsChartData?.length >=
                                      1 && (
                                      <SimpleBarChart
                                        data={[
                                          {
                                            data: topSellingProductsChartData,
                                            name: "Sales by traffic source",
                                          },
                                        ]}
                                        xAxisOptions={{
                                          // labelFormatter: (value) =>
                                          //   `PKR${formatNumber(value)}`, // Formatting labels if needed
                                          labelFormatter: (value) => {
                                            return `${
                                              value ? formatNumber(value) : 0
                                            }`;
                                          },
                                        }}
                                        yAxisOptions={{
                                          labelFormatter: (value) =>
                                            `${value.toLocaleString()}`, // Format y-axis labels as currency
                                        }}
                                        showLegend={false}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="customer-cohort-analysis"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/customer_cohort_analysis"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <CohortAnalysis />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Customer cohort analysis report"
                                      href="/store/essentialss-pk/reports/customer_cohort_analysis"
                                      tabIndex={0}
                                      aria-describedby=":rg4:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <img
                                      src={cohortAnalysis}
                                      alt="Cohort Analysis"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="returning-customer-rate"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/customers_over_time?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <ReturningCustomerRate />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Returning customer rate report"
                                      href="/store/essentialss-pk/reports/customers_over_time?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex={0}
                                      aria-describedby=":rll:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fill-rule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          {data?.returningCustomerRate?.[0]?.returningCustomerRate?.toFixed(
                                            2
                                          )}
                                          %
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {returningChartData?.length >= 1 && (
                                      <PolarisVizProvider>
                                        <StackedAreaChart
                                          data={returningChartData}
                                          isAnimated={false}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sales-attributed-to-marketing"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sales_attributed_to_marketing?since=2021-06-15&until=2024-06-15&over=month&filter=utm_campaign_name!=NULL"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SalesAttributeToMarketing />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sales attributed to marketing report"
                                      href="/store/essentialss-pk/reports/sales_attributed_to_marketing?since=2021-06-15&until=2024-06-15&over=month&filter=utm_campaign_name!=NULL"
                                      tabIndex="0"
                                      aria-describedby=":rlr:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div className="_Container_sdaxj_9 _GradientBackground_sdaxj_18">
                                      <p className="_Metric_sdaxj_3">
                                        Rs&nbsp;0.00
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sessions-by-location"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sessions_by_location?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SessionsByLocations />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sessions by location report"
                                      href="/store/essentialss-pk/reports/sessions_by_location?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rms:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {sessionsByLocationsChartData?.length >=
                                      1 && (
                                      <SimpleBarChart
                                        data={[
                                          {
                                            data: sessionsByLocationsChartData,
                                            name: "Sales by traffic source",
                                          },
                                        ]}
                                        xAxisOptions={{
                                          // labelFormatter: (value) =>
                                          //   `PKR${formatNumber(value)}`, // Formatting labels if needed
                                          labelFormatter: (value) => {
                                            return `${
                                              value ? formatNumber(value) : 0
                                            }`;
                                          },
                                        }}
                                        yAxisOptions={{
                                          labelFormatter: (value) =>
                                            `${value.toLocaleString()}`, // Format y-axis labels as currency
                                        }}
                                        showLegend={false}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sessions-by-device-type"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sessions_by_device?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SessionsByDevices />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sessions by device type report"
                                      href="/store/essentialss-pk/reports/sessions_by_device?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rmv:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fill-rule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div className="_DonutChartContainer_z1fuz_1">
                                      {donutChartData?.length >= 1 && (
                                        <PolarisVizProvider
                                          themes={{
                                            Light: {
                                              arc: {
                                                cornerRadius: 0,
                                              },
                                            },
                                          }}
                                        >
                                          <DonutChart
                                            labelFormatter={(value) => {
                                              return `${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            }}
                                            data={donutChartData}
                                            legendPosition="bottom"
                                          />
                                        </PolarisVizProvider>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sessions-by-social-source"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sessions_by_referrer?since=2021-06-15&until=2024-06-15&over=month&referrer_source=Social"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SessionsBySocialSource />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sessions by social source report"
                                      href="/store/essentialss-pk/reports/sessions_by_referrer?since=2021-06-15&until=2024-06-15&over=month&referrer_source=Social"
                                      tabIndex="0"
                                      aria-describedby=":rn2:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {sessionsBySocialSourceChartData?.length >=
                                      1 && (
                                      <SimpleBarChart
                                        data={[
                                          {
                                            data: sessionsBySocialSourceChartData,
                                            name: "Sales by traffic source",
                                          },
                                        ]}
                                        xAxisOptions={{
                                          // labelFormatter: (value) =>
                                          //   `PKR${formatNumber(value)}`, // Formatting labels if needed
                                          labelFormatter: (value) => {
                                            return `${
                                              value ? formatNumber(value) : 0
                                            }`;
                                          },
                                        }}
                                        yAxisOptions={{
                                          labelFormatter: (value) =>
                                            `${value.toLocaleString()}`, // Format y-axis labels as currency
                                        }}
                                        showLegend={false}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sales-by-social-source"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sales_by_referrer?since=2021-06-15&until=2024-06-15&over=month&referrer_source=Social"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SalesBySocialSource />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sales by social source report"
                                      href="/store/essentialss-pk/reports/sales_by_referrer?since=2021-06-15&until=2024-06-15&over=month&referrer_source=Social"
                                      tabIndex={0}
                                      aria-describedby=":rlu:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fill-rule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {salesBySocialSourceChartData?.length >=
                                      1 && (
                                      <BarChart
                                        data={[
                                          {
                                            data: salesBySocialSourceChartData,
                                            name: "Sales by traffic source",
                                          },
                                        ]}
                                        yAxisOptions={{
                                          // labelFormatter: (value) =>
                                          //   `${value.toLocaleString()}`, // Format y-axis labels as currency
                                          labelFormatter: (value) => {
                                            return `PKR${
                                              value ? formatNumber(value) : 0
                                            }`;
                                          },
                                        }}
                                        showLegend={false}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="top-referrers-by-sessions"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Box"
                                  style={{
                                    "--pc-box-padding-block-end-xs":
                                      "var(--p-space-200)",
                                  }}
                                >
                                  <div className="_Header_1xiwl_25">
                                    <div className="_Title_1xiwl_59">
                                      <TopReferrsBySessions />
                                    </div>
                                  </div>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div
                                      className="Polaris-Bleed"
                                      style={{
                                        "--pc-bleed-margin-inline-start-xs":
                                          "var(--p-space-400)",
                                        "--pc-bleed-margin-inline-end-xs":
                                          "var(--p-space-400)",
                                      }}
                                    >
                                      <div className="">
                                        <DataTable
                                          columnContentTypes={
                                            columnContentTypes
                                          }
                                          headings={[]}
                                          rows={rowsReferrers}
                                          truncate
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sessions-by-traffic-source"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sessions_by_referrer?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SessionsByTrafficSource />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sessions by traffic source report"
                                      href="/store/essentialss-pk/reports/sessions_by_referrer?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rn6:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div className="_VisualizationWrapper_xmyst_1">
                                      {sessionsByTrafficSourceChartData?.length >=
                                        1 && (
                                        <SimpleBarChart
                                          data={[
                                            {
                                              data: sessionsByTrafficSourceChartData,
                                              name: "Sales by traffic source",
                                            },
                                          ]}
                                          xAxisOptions={{
                                            // labelFormatter: (value) =>
                                            //   `PKR${formatNumber(value)}`, // Formatting labels if needed
                                            labelFormatter: (value) => {
                                              return `${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          yAxisOptions={{
                                            labelFormatter: (value) =>
                                              `${value.toLocaleString()}`, // Format y-axis labels as currency
                                          }}
                                          showLegend={false}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div>
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="sales-by-traffic-source"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": "",
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sales_by_referrer?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <SalesByTrafficSource />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span>
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Sales by traffic source report"
                                      href="/store/essentialss-pk/reports/sales_by_referrer?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rm1:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div className="_VisualizationWrapper_xmyst_1">
                                      {salesByTrafficSourceChartData?.length >=
                                        1 && (
                                        <SimpleBarChart
                                          data={[
                                            {
                                              data: salesByTrafficSourceChartData,
                                              name: "Sales by traffic source",
                                            },
                                          ]}
                                          xAxisOptions={{
                                            // labelFormatter: (value) =>
                                            //   `PKR${formatNumber(value)}`, // Formatting labels if needed
                                            labelFormatter: (value) => {
                                              return `PKR${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          yAxisOptions={{
                                            labelFormatter: (value) =>
                                              `${value.toLocaleString()}`, // Format y-axis labels as currency
                                          }}
                                          showLegend={false}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="top-landing-pages-by-sessions"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sessions_by_landing_page?since=2021-06-15&amp;until=2024-06-15&amp;over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <TopLandingPages />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Top landing pages by sessions report"
                                      href="/store/essentialss-pk/reports/sessions_by_landing_page?since=2021-06-15&amp;until=2024-06-15&amp;over=month"
                                      tabIndex="0"
                                      aria-describedby=":rn9:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div
                                      className="Polaris-Bleed"
                                      style={{
                                        "--pc-bleed-margin-inline-start-xs":
                                          "var(--p-space-400)",
                                        "--pc-bleed-margin-inline-end-xs":
                                          "var(--p-space-400)",
                                      }}
                                    >
                                      <div className="">
                                        <DataTable
                                          columnContentTypes={
                                            columnContentTypes
                                          }
                                          headings={[]}
                                          rows={landingPageRows}
                                          truncate
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="fulfilled-orders-over-time"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/fulfillment_over_time?since=2021-06-15&amp;until=2024-06-15&amp;over=month&amp;show=orders_fulfilled"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <FulFilledOrdersOverTime />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Fulfilled orders over time report"
                                      href="/store/essentialss-pk/reports/fulfillment_over_time?since=2021-06-15&amp;until=2024-06-15&amp;over=month&amp;show=orders_fulfilled"
                                      tabIndex="0"
                                      aria-describedby=":rg0:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          {data?.fulFilledOrders?.totalFulFilledOrders?.toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {fulFilledOrdersChartData?.length >= 1 && (
                                      <PolarisVizProvider themes={{}}>
                                        <LineChart
                                          theme="default"
                                          data={fulFilledOrdersChartData}
                                          yAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return `${
                                                value ? formatNumber(value) : 0
                                              }`;
                                            },
                                          }}
                                          xAxisOptions={{
                                            hide: false,
                                            labelFormatter: (value) => {
                                              return intervalFormat ===
                                                "%Y-%m-%d %H"
                                                ? dayjs(value).format("hh:mm A")
                                                : intervalFormat === "%Y-%m-%d"
                                                ? dayjs(value).format("DD MMM")
                                                : dayjs(value).format(
                                                    "MMM YYYY"
                                                  );
                                            },
                                          }}
                                          tooltipOptions={{
                                            renderTooltipContent: (data) => (
                                              <div
                                                class="Polaris-Box"
                                                style={{
                                                  "--pc-box-background":
                                                    "var(--p-color-bg-surface)",
                                                  "--pc-box-border-radius":
                                                    "var(--p-border-radius-200)",
                                                  "--pc-box-min-width": "150px",
                                                  "--pc-box-padding-block-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-block-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-start-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-padding-inline-end-xs":
                                                    "var(--p-space-200)",
                                                  "--pc-box-shadow":
                                                    "var(--p-shadow-300)",
                                                }}
                                              >
                                                <div
                                                  class="Polaris-BlockStack"
                                                  style={{
                                                    "--pc-block-stack-order":
                                                      "column",
                                                    "--pc-block-stack-gap-xs":
                                                      "var(--p-space-100)",
                                                  }}
                                                >
                                                  <div
                                                    class="Polaris-BlockStack"
                                                    style={{
                                                      "--pc-block-stack-order":
                                                        "column",
                                                      "--pc-block-stack-gap-xs":
                                                        "var(--p-space-200)",
                                                    }}
                                                  >
                                                    <div
                                                      class="Polaris-Box"
                                                      style={{ opacity: 1 }}
                                                    >
                                                      <div
                                                        class="Polaris-InlineStack"
                                                        style={{
                                                          "--pc-inline-stack-align":
                                                            "space-between",
                                                          "--pc-inline-stack-block-align":
                                                            "center",
                                                          "--pc-inline-stack-wrap":
                                                            "nowrap",
                                                          "--pc-inline-stack-gap-xs":
                                                            "var(--p-space-300)",
                                                          "--pc-inline-stack-flex-direction-xs":
                                                            "row",
                                                        }}
                                                      >
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          s
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-100)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div class="Polaris-Box">
                                                            <span
                                                              class="_Container_8b5v1_1"
                                                              style={{
                                                                height: "2px",
                                                                width: "12px",
                                                              }}
                                                            >
                                                              <svg
                                                                class="_SVG_8b5v1_5"
                                                                height="2px"
                                                                viewBox="0 0 12 2"
                                                                width="12px"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <defs>
                                                                  <linearGradient
                                                                    id="linePreviewGradient-40"
                                                                    x1="0%"
                                                                    x2="100%"
                                                                    y1="0%"
                                                                    y2="0%"
                                                                    gradientUnits="userSpaceOnUse"
                                                                  >
                                                                    <stop
                                                                      offset="0%"
                                                                      stop-color="#0A97D5"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                    <stop
                                                                      offset="100%"
                                                                      stop-color="#50C3F7"
                                                                      stop-opacity="1"
                                                                    ></stop>
                                                                  </linearGradient>
                                                                </defs>
                                                                <path
                                                                  d="M1 1 H12"
                                                                  stroke="url(#linePreviewGradient-40)"
                                                                  stroke-linejoin="round"
                                                                  stroke-linecap="round"
                                                                  stroke-width="2"
                                                                  stroke-dasharray="none"
                                                                ></path>
                                                              </svg>
                                                            </span>
                                                          </div>
                                                          <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                                                            {intervalFormat ===
                                                            "%Y-%m-%d %H"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                )?.format(
                                                                  "DD MMM, hh:mm A"
                                                                )
                                                              : intervalFormat ===
                                                                "%Y-%m-%d"
                                                              ? dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "DD-MMM-YYYY"
                                                                )
                                                              : dayjs(
                                                                  data
                                                                    ?.dataSeries?.[0]
                                                                    ?.data?.[
                                                                    data
                                                                      ?.activeIndex
                                                                  ]?.key
                                                                ).format(
                                                                  "MMM YYYY"
                                                                )}
                                                          </p>
                                                        </div>
                                                        <div
                                                          class="Polaris-InlineStack"
                                                          style={{
                                                            "--pc-inline-stack-block-align":
                                                              "center",
                                                            "--pc-inline-stack-wrap":
                                                              "nowrap",
                                                            "--pc-inline-stack-gap-xs":
                                                              "var(--p-space-200)",
                                                            "--pc-inline-stack-flex-direction-xs":
                                                              "row",
                                                          }}
                                                        >
                                                          <div
                                                            class="_Value_18pd5_1"
                                                            style={{
                                                              marginLeft: "0px",
                                                            }}
                                                          >
                                                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                                              {data?.dataSeries?.[0]?.data?.[
                                                                data
                                                                  ?.activeIndex
                                                              ]?.value?.toLocaleString()}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ),
                                          }}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="first-time-customer-rate"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/customers_over_time?since=2021-06-15&amp;until=2024-06-15&amp;over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <CustomerOverTime />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Customers over time (first time vs returning) report"
                                      href="/store/essentialss-pk/reports/customers_over_time?since=2021-06-15&amp;until=2024-06-15&amp;over=month"
                                      tabIndex="0"
                                      aria-describedby=":rlo:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          {data?.customersOverTime?.[0]?.totalCustomersOverall?.toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    {returningChartData?.length >= 1 && (
                                      <PolarisVizProvider>
                                        <StackedAreaChart
                                          data={returningChartData}
                                          isAnimated={false}
                                        />
                                      </PolarisVizProvider>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    role="button"
                    tabIndex="0"
                    aria-disabled="true"
                    aria-roledescription="sortable"
                    aria-describedby="DndDescribedBy-2"
                    style={{ opacity: 1 }}
                  >
                    <div className="">
                      <div
                        className="_DashboardMetricCard_pkhhu_3"
                        id="products-by-sell-through-rate"
                      >
                        <div className="_CardWrapper_1xiwl_11">
                          <div
                            className="Polaris-ShadowBevel"
                            style={{
                              "--pc-shadow-bevel-z-index": 32,
                              "--pc-shadow-bevel-content-xs": '""',
                              "--pc-shadow-bevel-box-shadow-xs":
                                "var(--p-shadow-100)",
                              "--pc-shadow-bevel-border-radius-xs":
                                "var(--p-border-radius-300)",
                            }}
                          >
                            <div
                              className="Polaris-Box"
                              style={{
                                "--pc-box-background":
                                  "var(--p-color-bg-surface-transparent)",
                                "--pc-box-min-height": "100%",
                                "--pc-box-overflow-x": "clip",
                                "--pc-box-overflow-y": "clip",
                                "--pc-box-padding-block-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-start-xs":
                                  "var(--p-space-400)",
                                "--pc-box-padding-inline-end-xs":
                                  "var(--p-space-400)",
                              }}
                            >
                              <div className="_MetricLayout_1xiwl_1">
                                <div
                                  className="Polaris-Bleed"
                                  style={{
                                    "--pc-bleed-margin-block-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-start-xs":
                                      "var(--p-space-400)",
                                    "--pc-bleed-margin-inline-end-xs":
                                      "var(--p-space-400)",
                                  }}
                                >
                                  <a
                                    data-polaris-unstyled="true"
                                    className="_Button_1xiwl_82 _HeaderButton_1xiwl_25"
                                    href="/store/essentialss-pk/reports/sell_through_rate?since=2021-06-15&until=2024-06-15&over=month"
                                  >
                                    <div className="_Header_1xiwl_25">
                                      <div className="_Title_1xiwl_59 _HasPrimaryAction_1xiwl_64">
                                        <ProductsBySelfThrough />
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div className="_PrimaryAction_1xiwl_68">
                                  <span className="">
                                    <a
                                      data-polaris-unstyled="true"
                                      className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                      aria-label="View the Products by sell-through rate report"
                                      href="/store/essentialss-pk/reports/sell_through_rate?since=2021-06-15&until=2024-06-15&over=month"
                                      tabIndex="0"
                                      aria-describedby=":rme:"
                                      data-polaris-tooltip-activator="true"
                                    >
                                      <span className="Polaris-Button__Icon">
                                        <span className="Polaris-Icon">
                                          <svg
                                            viewBox="0 0 20 20"
                                            className="Polaris-Icon__Svg"
                                            focusable="false"
                                            aria-hidden="true"
                                          >
                                            <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                                            <path
                                              fillRule="evenodd"
                                              d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                                            ></path>
                                            <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                                            <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </span>
                                </div>
                                <div className="_Content_1xiwl_42">
                                  <div className="_PrimaryMetric_1ey7s_1">
                                    <div className="_ValueWrapper_1ey7s_60">
                                      <div className="_PrimaryMetricWrapper_1ey7s_70">
                                        <p className="_PrimaryMetricValue_1ey7s_25">
                                          34.5%
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="_VisualizationWrapper_xmyst_1">
                                    <div
                                      className="Polaris-Bleed"
                                      style={{
                                        "--pc-bleed-margin-inline-start-xs":
                                          "var(--p-space-400)",
                                        "--pc-bleed-margin-inline-end-xs":
                                          "var(--p-space-400)",
                                      }}
                                    >
                                      <div className="">
                                        <DataTable
                                          columnContentTypes={
                                            columnContentTypes
                                          }
                                          headings={[]}
                                          rows={rowsSelfThrough}
                                          truncate
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="Polaris-FooterHelp"
                style={{ "--pc-footer-help-align": "center" }}
              >
                <p class="Polaris-Text--root Polaris-Text--bodySm">
                  Learn more about{" "}
                  <a
                    href="https://help.shopify.com/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                    rel="noopener noreferrer"
                    target="_blank"
                    data-polaris-unstyled="true"
                    class="Polaris-Link"
                  >
                    overview dashboard
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;

import React, { useState } from "react";
import { Popover } from "@shopify/polaris";

function SalesByChannel() {
  const [popoverActive, setPopoverActive] = useState(false);

  const activator = (
    <div className="_Title_1633q_1">
      <h2 className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
        <span className="_WrappedContent_18yn4_29">
          Returning customer rate
        </span>
      </h2>
    </div>
  );

  const textFieldMarkup = (
    <div
      className="Polaris-Box"
      style={{
        "--pc-box-padding-block-start-xs": "var(--p-space-400)",
        "--pc-box-padding-block-end-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
      }}
    >
      <div
        className="Polaris-BlockStack"
        style={{
          "--pc-block-stack-inline-align": "start",
          "--pc-block-stack-order": "column",
          "--pc-block-stack-gap-xs": "var(--p-space-300)",
        }}
      >
        <div
          className="Polaris-BlockStack"
          style={{
            "--pc-block-stack-inline-align": "start",
            "--pc-block-stack-order": "column",
            "--pc-block-stack-gap-xs": "var(--p-space-200)",
          }}
        >
          <p className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--semibold">
            Returning customer rate
          </p>
          <p>
            The percentage of customers that have placed more than one order
            from your store, out of all customers that placed an order, within
            the selected date range.
          </p>
          <div
            className="Polaris-Box"
            style={{
              "--pc-box-background": "var(--p-color-bg)",
              "--pc-box-border-radius": "var(--p-border-radius-100)",
              "--pc-box-padding-block-start-xs": "var(--p-space-200)",
              "--pc-box-padding-block-end-xs": "var(--p-space-200)",
              "--pc-box-padding-inline-start-xs": "var(--p-space-200)",
              "--pc-box-padding-inline-end-xs": "var(--p-space-200)",
              "--pc-box-width": "100%",
            }}
          >
            <p className="_Formula_zq6ol_10">
              <span className="_FormulaResult_zq6ol_16">
                Returning customer rate{" "}
              </span>
              <span className="_FormulaBracketOrEquals_zq6ol_28">= </span>
              <span className="_FormulaBracketOrEquals_zq6ol_28">[</span>
              <span className="_FormulaVariable_zq6ol_10">returning</span>
              <span className="_FormulaVariable_zq6ol_10">customers</span>
              <span className="_FormulaOperatorOrNumber_zq6ol_22">/</span>
              <span className="_FormulaVariable_zq6ol_10">total</span>
              <span className="_FormulaVariable_zq6ol_10">customers</span>
              <span className="_FormulaBracketOrEquals_zq6ol_28">]</span>
              <span className="_FormulaOperatorOrNumber_zq6ol_22">*</span>
              <span className="_FormulaOperatorOrNumber_zq6ol_22">100</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      onMouseEnter={() => setPopoverActive(true)}
      onMouseLeave={() => setPopoverActive(false)}
    >
      <Popover
        active={popoverActive}
        activator={activator}
        preferredAlignment="left"
        autofocusTarget="first-node"
        onClose={() => setPopoverActive(false)}
      >
        <Popover.Pane fixed>
          <div
            style={{
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {textFieldMarkup}
          </div>
        </Popover.Pane>
      </Popover>
    </div>
  );
}

export default SalesByChannel;

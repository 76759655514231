import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API_URL;

const createRequest = (url) => ({
  method: "GET",
  url,
});

export const spaceApi = createApi({
  reducerPath: "apisSpace",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {},
  }),
  tagTypes: ["orders", "orderAnalytics", "dashboardAnalytics", "analytics"],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ page }) => createRequest(`/orders/?page=${page}`),
      providesTags: ["orders"],
    }),
    orderAnalytics: builder.query({
      query: ({ start, end }) =>
        createRequest(`/orderAnalytics?start=${start}&end=${end}`),
      providesTags: ["orderAnalytics"],
    }),
    dashboardAnalytics: builder.query({
      query: ({ start, end }) =>
        createRequest(`/dashboardAnalytics?start=${start}&end=${end}`),
      providesTags: ["dashboardAnalytics"],
    }),
    dashboardCharts: builder.query({
      query: ({ start, end }) =>
        createRequest(`/dashboardCharts?start=${start}&end=${end}`),
      providesTags: ["dashboardCharts"],
    }),
    analytics: builder.query({
      query: ({ start, end }) =>
        createRequest(`/analytics?start=${start}&end=${end}`),
      providesTags: ["analytics"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useOrderAnalyticsQuery,
  useDashboardChartsQuery,
  useDashboardAnalyticsQuery,
  useAnalyticsQuery,
} = spaceApi;

import React, { useEffect, useState } from "react";
import Allchannels from "../Allchannels";
import Calender from "../Calender";
import OnlineStore from "./onlineStore";
import TotalSales from "./totalSales";
import TotalOrders from "./totalOrders";
import ConversionRate from "./conversionRate";
import DashboardLineChart from "./dashboardLineChart";
import { formatReplace } from "../../utils/formatReplace";
import { useDashboardAnalyticsQuery } from "../../slices/spaceApi";
import dayjs from "dayjs";
import { formatNumber } from "../../utils/formatNumber";
import { useGlobalContext } from "../../context/globalContext";

const DashboardAnalytics = () => {
  const [tabValue, setTabValue] = useState(1);
  const { homeDate } = useGlobalContext();

  const startDate = dayjs(homeDate?.since).format("YYYY-MM-DD");
  const endDate = dayjs(homeDate?.until).format("YYYY-MM-DD");

  const { data: homeData, isLoading } = useDashboardAnalyticsQuery(
    {
      start: startDate,
      end: endDate,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    // Select all elements with the class _TrendIndicator_13puv_21
    const trendIndicators = document.querySelectorAll(
      "._TrendIndicator_13puv_21"
    );

    // Iterate over each trendIndicator element
    trendIndicators.forEach((trendIndicator) => {
      // Select the svg element inside the trendIndicator
      const svgElement = trendIndicator.querySelector("svg");

      if (svgElement) {
        // Select the inner g element
        const gElement = svgElement.querySelector("g");

        if (gElement) {
          // Get the bounding box of the g element to determine its width
          const gBBox = gElement.getBBox();

          // Set the width of the svg element based on the bounding box width
          svgElement.setAttribute("width", gBBox.width?.toString());

          // Adjust the viewBox attribute to scale the content correctly
          // const viewBoxWidth = parseFloat(
          //   svgElement.getAttribute("viewBox").split(" ")[2]
          // );
          const newViewBox = `0 0 ${gBBox.width} 16`;
          svgElement.setAttribute("viewBox", newViewBox);
        }
      }
    });
  }, [homeData]);

  return (
    <>
      <div class="_analyticsContainer_ql3ja_3">
        <div
          class="Polaris-BlockStack"
          style={{
            "--pc-block-stack-order": "column",
            "--pc-block-stack-gap-xs": "var(--p-space-400)",
          }}
        >
          {/* Calendar & Channels */}
          <div
            class="Polaris-BlockStack"
            style={{
              "--pc-block-stack-order": "column",
              "--pc-block-stack-gap-xs": "var(--p-space-400)",
            }}
          >
            <div>
              <div class="Polaris-LegacyStack">
                <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                  <div class="Polaris-LegacyStack Polaris-LegacyStack--spacingTight">
                    <div class="Polaris-LegacyStack__Item">
                      <Calender />
                    </div>
                    <div class="Polaris-LegacyStack__Item">
                      <Allchannels />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs/Box */}
          <div
            class="Polaris-ShadowBevel"
            style={{
              "--pc-shadow-bevel-z-index": "32",
              "--pc-shadow-bevel-content-xs": "",
              "--pc-shadow-bevel-box-shadow-xs": "var(--p-shadow-100)",
              "--pc-shadow-bevel-border-radius-xs":
                "var(--p-border-radius-300)",
            }}
          >
            <div
              class="Polaris-Box"
              style={{
                "--pc-box-background": "var(--p-color-bg-surface)",
                "--pc-box-min-height": "100%",
                "--pc-box-overflow-x": "clip",
                "--pc-box-overflow-y": "clip",
                "--pc-box-padding-block-start-xs": "var(--p-space-200)",
                "--pc-box-padding-block-end-xs": "var(--p-space-200)",
                "--pc-box-padding-inline-start-xs": "var(--p-space-200)",
                "--pc-box-padding-inline-end-xs": "var(--p-space-200)",
              }}
            >
              <div>
                <div class="_metricsButtonContainer_sakxn_14">
                  <div class="_metricButtonStack_sakxn_1">
                    <div
                      class="Polaris-InlineStack"
                      style={{
                        "--pc-inline-stack-wrap": "nowrap",
                        "--pc-inline-stack-gap-xs": "var(--p-space-050)",
                        "--pc-inline-stack-flex-direction-xs": "row",
                      }}
                    >
                      <div
                        onClick={() => setTabValue(1)}
                        class="_metricsButton_sakxn_14 _metricsButtonWidth_sakxn_130 _metricButtonSelected_sakxn_112"
                        role="button"
                        tabindex="0"
                      >
                        <div
                          class="Polaris-Box"
                          style={{
                            "--pc-box-background":
                              tabValue === 1 ? "var(--p-color-bg)" : "",
                            "--pc-box-background":
                              tabValue === 1 ? "var(--p-color-bg)" : "",
                            "--pc-box-border-radius":
                              "var(--p-border-radius-200)",
                            "--pc-box-padding-block-start-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-block-end-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-inline-start-xs":
                              "var(--p-space-300)",
                            "--pc-box-padding-inline-end-xs":
                              "var(--p-space-300)",
                          }}
                        >
                          <div class="_MetricLayout_zn76l_1 _Compact_zn76l_70">
                            <div
                              class="Polaris-Box"
                              style={{
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-100)",
                              }}
                            >
                              <div class="_Header_zn76l_25">
                                <div class="_Title_zn76l_55 _HasPrimaryAction_zn76l_60">
                                  <OnlineStore />
                                </div>
                              </div>
                            </div>

                            <div class="_PrimaryAction_zn76l_64">
                              <div class="_pencilContainer_sakxn_55">
                                <div>
                                  <button
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                    aria-label="Edit Metric"
                                    type="button"
                                    tabindex="0"
                                    aria-controls=":rod:"
                                    aria-owns=":rod:"
                                    aria-expanded="false"
                                    data-state="closed"
                                  >
                                    <span class="Polaris-Button__Icon">
                                      <span class="Polaris-Icon">
                                        <svg
                                          viewBox="0 0 20 20"
                                          class="Polaris-Icon__Svg"
                                          focusable="false"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M15.655 4.344a2.695 2.695 0 0 0-3.81 0l-.599.599-.009-.009-1.06 1.06.008.01-5.88 5.88a2.75 2.75 0 0 0-.805 1.944v1.922a.75.75 0 0 0 .75.75h1.922a2.75 2.75 0 0 0 1.944-.806l7.54-7.539a2.695 2.695 0 0 0 0-3.81Zm-4.409 2.72-5.88 5.88a1.25 1.25 0 0 0-.366.884v1.172h1.172c.331 0 .65-.132.883-.366l5.88-5.88-1.689-1.69Zm2.75.629.599-.599a1.195 1.195 0 1 0-1.69-1.689l-.598.599 1.69 1.689Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="_Content_zn76l_42">
                              <div class="_PrimaryMetric_13puv_1 _Compact_13puv_8">
                                <div class="_ValueWrapper_13puv_53">
                                  <div class="_PrimaryMetricWrapper_13puv_63">
                                    <p class="_PrimaryMetricValue_13puv_25 _Compact_13puv_8">
                                      {homeData?.totalSessions?.toLocaleString()}
                                    </p>
                                  </div>
                                  <div class="_TrendIndicator_13puv_21">
                                    <svg
                                      // viewBox="0 0 34 16"
                                      height="16"
                                      width="34"
                                      role="img"
                                      class="_SVG_1ou8g_150"
                                      tabindex="-1"
                                    >
                                      <title>
                                        Change of{" "}
                                        {formatNumber(
                                          homeData?.sessionsChange?.change.toFixed(
                                            0
                                          )
                                        )}
                                        %
                                      </title>
                                      {homeData?.sessionsChange?.direction ===
                                      "up" ? (
                                        <g color="#078d4e">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M1 .25a.75.75 0 1 0 0 1.5h2.19L.594 4.345a.75.75 0 0 0 1.06 1.06L4.25 2.811V5a.75.75 0 0 0 1.5 0V1A.748.748 0 0 0 5 .25H1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.57"
                                          >
                                            {formatNumber(
                                              homeData?.sessionsChange?.change.toFixed(
                                                0
                                              )
                                            )}
                                            %
                                          </text>
                                        </g>
                                      ) : (
                                        <g color="var(--p-color-text-secondary)">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M5.75 1a.75.75 0 0 0-1.5 0v2.19L1.655.594a.75.75 0 1 0-1.06 1.06L3.189 4.25H1a.75.75 0 0 0 0 1.5h4a.748.748 0 0 0 .529-.218l.001-.002.002-.001A.748.748 0 0 0 5.75 5V1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.96"
                                          >
                                            {formatNumber(
                                              homeData?.sessionsChange?.change.toFixed(
                                                0
                                              )
                                            )}
                                            %
                                          </text>
                                        </g>
                                      )}
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={() => setTabValue(2)}
                        class="_metricsButton_sakxn_14 _metricsButtonWidth_sakxn_130"
                        role="button"
                        tabindex="0"
                      >
                        <div
                          class="Polaris-Box"
                          style={{
                            "--pc-box-background":
                              tabValue === 2 ? "var(--p-color-bg-surface)" : "",
                            "--pc-box-background":
                              tabValue === 2 ? "var(--p-color-bg)" : "",
                            "--pc-box-border-radius":
                              "var(--p-border-radius-200)",
                            "--pc-box-padding-block-start-xs":
                              " var(--p-space-150)",
                            "--pc-box-padding-block-end-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-inline-start-xs":
                              " var(--p-space-300)",
                            "--pc-box-padding-inline-end-xs":
                              "var(--p-space-300)",
                          }}
                        >
                          <div class="_MetricLayout_zn76l_1 _Compact_zn76l_70">
                            <div
                              class="Polaris-Box"
                              style={{
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-100)",
                              }}
                            >
                              <div class="_Header_zn76l_25">
                                <div class="_Title_zn76l_55 _HasPrimaryAction_zn76l_60">
                                  <TotalSales />
                                </div>
                              </div>
                            </div>
                            <div class="_PrimaryAction_zn76l_64">
                              <div class="_pencilContainer_sakxn_55">
                                <div>
                                  <button
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                    aria-label="Edit Metric"
                                    type="button"
                                    tabindex="0"
                                    aria-controls=":r3k:"
                                    aria-owns=":r3k:"
                                    aria-expanded="false"
                                    data-state="closed"
                                  >
                                    <span class="Polaris-Button__Icon">
                                      <span class="Polaris-Icon">
                                        <svg
                                          viewBox="0 0 20 20"
                                          class="Polaris-Icon__Svg"
                                          focusable="false"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M15.655 4.344a2.695 2.695 0 0 0-3.81 0l-.599.599-.009-.009-1.06 1.06.008.01-5.88 5.88a2.75 2.75 0 0 0-.805 1.944v1.922a.75.75 0 0 0 .75.75h1.922a2.75 2.75 0 0 0 1.944-.806l7.54-7.539a2.695 2.695 0 0 0 0-3.81Zm-4.409 2.72-5.88 5.88a1.25 1.25 0 0 0-.366.884v1.172h1.172c.331 0 .65-.132.883-.366l5.88-5.88-1.689-1.69Zm2.75.629.599-.599a1.195 1.195 0 1 0-1.69-1.689l-.598.599 1.69 1.689Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="_Content_zn76l_42">
                              <div class="_PrimaryMetric_13puv_1 _Compact_13puv_8">
                                <div class="_ValueWrapper_13puv_53">
                                  <div class="_PrimaryMetricWrapper_13puv_63">
                                    <p class="_PrimaryMetricValue_13puv_25 _Compact_13puv_8">
                                      Rs&nbsp;
                                      {homeData?.totalSales
                                        ?.toFixed(2)
                                        ?.replace(formatReplace, ",")}
                                    </p>
                                  </div>
                                  <div class="_TrendIndicator_13puv_21">
                                    <svg
                                      viewBox="0 0 34 16"
                                      height="16"
                                      width="34"
                                      role="img"
                                      class="_SVG_1ou8g_150"
                                      tabindex="-1"
                                    >
                                      <title>
                                        Change of
                                        {formatNumber(
                                          homeData?.salesChange?.change.toFixed(
                                            0
                                          )
                                        )}
                                        %
                                      </title>
                                      {homeData?.salesChange?.direction ===
                                      "up" ? (
                                        <g color="#078d4e">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M1 .25a.75.75 0 1 0 0 1.5h2.19L.594 4.345a.75.75 0 0 0 1.06 1.06L4.25 2.811V5a.75.75 0 0 0 1.5 0V1A.748.748 0 0 0 5 .25H1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.57"
                                          >
                                            {formatNumber(
                                              homeData?.salesChange?.change.toFixed(
                                                0
                                              )
                                            )}
                                            %
                                          </text>
                                        </g>
                                      ) : (
                                        <g color="var(--p-color-text-secondary)">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M5.75 1a.75.75 0 0 0-1.5 0v2.19L1.655.594a.75.75 0 1 0-1.06 1.06L3.189 4.25H1a.75.75 0 0 0 0 1.5h4a.748.748 0 0 0 .529-.218l.001-.002.002-.001A.748.748 0 0 0 5.75 5V1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="15"
                                          >
                                            {formatNumber(
                                              homeData?.salesChange?.change.toFixed(
                                                0
                                              )
                                            )}
                                            %
                                          </text>
                                        </g>
                                      )}
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={() => setTabValue(3)}
                        class="_metricsButton_sakxn_14 _metricsButtonWidth_sakxn_130"
                        role="button"
                        tabindex="0"
                      >
                        <div
                          class="Polaris-Box"
                          style={{
                            "--pc-box-background":
                              tabValue === 3 ? "var(--p-color-bg-surface)" : "",
                            "--pc-box-background":
                              tabValue === 3 ? "var(--p-color-bg)" : "",
                            "--pc-box-border-radius":
                              "var(--p-border-radius-200)",
                            "--pc-box-padding-block-start-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-block-end-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-inline-start-xs":
                              "var(--p-space-300)",
                            "--pc-box-padding-inline-end-xs":
                              "var(--p-space-300)",
                          }}
                        >
                          <div class="_MetricLayout_zn76l_1 _Compact_zn76l_70">
                            <div
                              class="Polaris-Box"
                              style={{
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-100)",
                              }}
                            >
                              <div class="_Header_zn76l_25">
                                <div class="_Title_zn76l_55 _HasPrimaryAction_zn76l_60">
                                  <span id="DefinitionPopoverContent1-wrapper">
                                    <TotalOrders />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="_PrimaryAction_zn76l_64">
                              <div class="_pencilContainer_sakxn_55">
                                <div>
                                  <button
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                    aria-label="Edit Metric"
                                    type="button"
                                    tabindex="0"
                                    aria-controls=":r3m:"
                                    aria-owns=":r3m:"
                                    aria-expanded="false"
                                    data-state="closed"
                                  >
                                    <span class="Polaris-Button__Icon">
                                      <span class="Polaris-Icon">
                                        <svg
                                          viewBox="0 0 20 20"
                                          class="Polaris-Icon__Svg"
                                          focusable="false"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M15.655 4.344a2.695 2.695 0 0 0-3.81 0l-.599.599-.009-.009-1.06 1.06.008.01-5.88 5.88a2.75 2.75 0 0 0-.805 1.944v1.922a.75.75 0 0 0 .75.75h1.922a2.75 2.75 0 0 0 1.944-.806l7.54-7.539a2.695 2.695 0 0 0 0-3.81Zm-4.409 2.72-5.88 5.88a1.25 1.25 0 0 0-.366.884v1.172h1.172c.331 0 .65-.132.883-.366l5.88-5.88-1.689-1.69Zm2.75.629.599-.599a1.195 1.195 0 1 0-1.69-1.689l-.598.599 1.69 1.689Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="_Content_zn76l_42">
                              <div class="_PrimaryMetric_13puv_1 _Compact_13puv_8">
                                <div class="_ValueWrapper_13puv_53">
                                  <div class="_PrimaryMetricWrapper_13puv_63">
                                    <p class="_PrimaryMetricValue_13puv_25 _Compact_13puv_8">
                                      {homeData?.totalOrders?.toLocaleString()}
                                    </p>
                                  </div>
                                  <div class="_TrendIndicator_13puv_21">
                                    <svg
                                      viewBox="0 0 34 16"
                                      height="16"
                                      width="34"
                                      role="img"
                                      class="_SVG_1ou8g_150"
                                      tabindex="-1"
                                    >
                                      <title>
                                        Change of{" "}
                                        {formatNumber(
                                          homeData?.ordersChange?.change.toFixed(
                                            0
                                          )
                                        )}
                                        %
                                      </title>
                                      {homeData?.ordersChange?.direction ===
                                      "up" ? (
                                        <g color="#078d4e">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M1 .25a.75.75 0 1 0 0 1.5h2.19L.594 4.345a.75.75 0 0 0 1.06 1.06L4.25 2.811V5a.75.75 0 0 0 1.5 0V1A.748.748 0 0 0 5 .25H1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.57"
                                          >
                                            {formatNumber(
                                              homeData?.ordersChange?.change.toFixed(
                                                0
                                              )
                                            )}
                                            %
                                          </text>
                                        </g>
                                      ) : (
                                        <g color="var(--p-color-text-secondary)">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M5.75 1a.75.75 0 0 0-1.5 0v2.19L1.655.594a.75.75 0 1 0-1.06 1.06L3.189 4.25H1a.75.75 0 0 0 0 1.5h4a.748.748 0 0 0 .529-.218l.001-.002.002-.001A.748.748 0 0 0 5.75 5V1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.96"
                                          >
                                            {formatNumber(
                                              homeData?.ordersChange?.change.toFixed(
                                                0
                                              )
                                            )}
                                            %
                                          </text>
                                        </g>
                                      )}
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        onClick={() => setTabValue(4)}
                        class="_metricsButton_sakxn_14 _metricsButtonWidth_sakxn_130"
                        role="button"
                        tabindex="0"
                      >
                        <div
                          class="Polaris-Box"
                          style={{
                            "--pc-box-background":
                              tabValue === 4 ? "var(--p-color-bg-surface)" : "",
                            "--pc-box-background":
                              tabValue === 4 ? "var(--p-color-bg)" : "",
                            "--pc-box-border-radius":
                              "var(--p-border-radius-200)",
                            "--pc-box-padding-block-start-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-block-end-xs":
                              "var(--p-space-150)",
                            "--pc-box-padding-inline-start-xs":
                              "var(--p-space-300)",
                            "--pc-box-padding-inline-end-xs":
                              "var(--p-space-300)",
                          }}
                        >
                          <div class="_MetricLayout_zn76l_1 _Compact_zn76l_70">
                            <div
                              class="Polaris-Box"
                              style={{
                                "--pc-box-padding-block-end-xs":
                                  "var(--p-space-100)",
                              }}
                            >
                              <div class="_Header_zn76l_25">
                                <div class="_Title_zn76l_55 _HasPrimaryAction_zn76l_60">
                                  <ConversionRate />
                                </div>
                              </div>
                            </div>
                            <div class="_PrimaryAction_zn76l_64">
                              <div class="_pencilContainer_sakxn_55">
                                <div>
                                  <button
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                    aria-label="Edit Metric"
                                    type="button"
                                    tabindex="0"
                                    aria-controls=":r3o:"
                                    aria-owns=":r3o:"
                                    aria-expanded="false"
                                    data-state="closed"
                                  >
                                    <span class="Polaris-Button__Icon">
                                      <span class="Polaris-Icon">
                                        <svg
                                          viewBox="0 0 20 20"
                                          class="Polaris-Icon__Svg"
                                          focusable="false"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M15.655 4.344a2.695 2.695 0 0 0-3.81 0l-.599.599-.009-.009-1.06 1.06.008.01-5.88 5.88a2.75 2.75 0 0 0-.805 1.944v1.922a.75.75 0 0 0 .75.75h1.922a2.75 2.75 0 0 0 1.944-.806l7.54-7.539a2.695 2.695 0 0 0 0-3.81Zm-4.409 2.72-5.88 5.88a1.25 1.25 0 0 0-.366.884v1.172h1.172c.331 0 .65-.132.883-.366l5.88-5.88-1.689-1.69Zm2.75.629.599-.599a1.195 1.195 0 1 0-1.69-1.689l-.598.599 1.69 1.689Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="_Content_zn76l_42">
                              <div class="_PrimaryMetric_13puv_1 _Compact_13puv_8">
                                <div class="_ValueWrapper_13puv_53">
                                  <div class="_PrimaryMetricWrapper_13puv_63">
                                    <p class="_PrimaryMetricValue_13puv_25 _Compact_13puv_8">
                                      {homeData?.conversionRate}%
                                    </p>
                                  </div>
                                  <div class="_TrendIndicator_13puv_21">
                                    <svg
                                      viewBox="0 0 34 16"
                                      height="16"
                                      width="34"
                                      role="img"
                                      class="_SVG_1ou8g_150"
                                      tabindex="-1"
                                    >
                                      <title>
                                        Change of{" "}
                                        {homeData?.conversionRateChange?.change}
                                      </title>
                                      {homeData?.conversionRateChange
                                        ?.direction === "up" ? (
                                        <g color="#078d4e">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M1 .25a.75.75 0 1 0 0 1.5h2.19L.594 4.345a.75.75 0 0 0 1.06 1.06L4.25 2.811V5a.75.75 0 0 0 1.5 0V1A.748.748 0 0 0 5 .25H1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.57"
                                          >
                                            {formatNumber(
                                              homeData?.conversionRateChange
                                                ?.change &&
                                                Number(
                                                  homeData?.conversionRateChange
                                                    ?.change
                                                )?.toFixed(0)
                                            )}
                                            %
                                          </text>
                                        </g>
                                      ) : (
                                        <g color="var(--p-color-text-secondary)">
                                          <g transform="translate(0, 5.25)">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="6"
                                              height="6"
                                              fill="none"
                                              viewBox="0 0 6 6"
                                            >
                                              <path
                                                fill="currentColor"
                                                fill-rule="evenodd"
                                                d="M5.75 1a.75.75 0 0 0-1.5 0v2.19L1.655.594a.75.75 0 1 0-1.06 1.06L3.189 4.25H1a.75.75 0 0 0 0 1.5h4a.748.748 0 0 0 .529-.218l.001-.002.002-.001A.748.748 0 0 0 5.75 5V1Z"
                                                clip-rule="evenodd"
                                              ></path>
                                            </svg>
                                          </g>
                                          <text
                                            x="9.5"
                                            y="9.5"
                                            font-size="11"
                                            fill="currentColor"
                                            font-weight="600"
                                            dominant-baseline="middle"
                                            font-family="-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
                                            text-rendering="geometricPrecision"
                                            width="24.96"
                                          >
                                            {formatNumber(
                                              homeData?.conversionRateChange
                                                ?.change &&
                                                Number(
                                                  homeData?.conversionRateChange
                                                    ?.change
                                                )?.toFixed(0)
                                            )}
                                            %
                                          </text>
                                        </g>
                                      )}
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="_expandButton_sakxn_19">
                    <button
                      class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                      aria-label="Collapse chart"
                      role="tab"
                      type="button"
                      aria-controls="home-metrics-collapsible-section"
                      aria-expanded="true"
                    >
                      <span class="Polaris-Button__Icon">
                        <span class="Polaris-Icon">
                          <svg
                            viewBox="0 0 20 20"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14.53 12.28a.75.75 0 0 1-1.06 0l-3.47-3.47-3.47 3.47a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              {/* Graph */}
              <div class="_MetricCardContainer_sakxn_136">
                <div class="_MetricLayout_zn76l_1">
                  <div
                    class="Polaris-Box"
                    style={{
                      "--pc-box-padding-block-end-xs": "var(--p-space-200)",
                    }}
                  >
                    <div class="_Header_zn76l_25">
                      <div class="_Title_zn76l_55 _HasPrimaryAction_zn76l_60">
                        <div class="_Title_1633q_1"></div>
                      </div>
                    </div>
                  </div>
                  <div class="_PrimaryAction_zn76l_64">
                    <span class="">
                      <a
                        data-polaris-unstyled="true"
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        href="/store/essentialss-pk/reports/sessions_over_time?since=2020-01-01&amp;until=2023-07-01&amp;over=month"
                        tabindex="0"
                        aria-describedby=":r3p:"
                        data-polaris-tooltip-activator="true"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              aria-hidden="true"
                            >
                              <path d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.9c0 .853 0 1.447.038 1.91.037.453.107.714.207.912.216.423.56.767.984.983.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.2a.75.75 0 0 1 0 1.5h-.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z"></path>
                              <path
                                fill-rule="evenodd"
                                d="M10.25 13a2.75 2.75 0 1 1 5.152 1.34l1.128 1.13a.75.75 0 1 1-1.06 1.06l-1.13-1.129a2.75 2.75 0 0 1-4.09-2.402Zm2.75-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
                              ></path>
                              <path d="M7 7.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z"></path>
                              <path d="M7.75 10a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5Z"></path>
                            </svg>
                          </span>
                        </span>
                      </a>
                    </span>
                  </div>

                  <div class="_Content_zn76l_42">
                    <div class="_VisualizationWrapper_xmyst_1">
                      <DashboardLineChart tabValue={tabValue} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAnalytics;

import React, { useState } from "react";
import { Popover } from "@shopify/polaris";

function ConversionRate({ analytics }) {
  const [popoverActive, setPopoverActive] = useState(false);

  /* Your app's feature/context specific activator here */
  const activator = (
    <div className="_Title_1633q_1">
      <h2 className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
        <span className="_WrappedContent_18yn4_29">
          {analytics ? "Online store conversion rate" : "Conversion rate"}{" "}
        </span>
      </h2>
    </div>
  );

  const textFieldMarkup = (
    <div
      className="Polaris-Box"
      style={{
        "--pc-box-padding-block-start-xs": "var(--p-space-400)",
        "--pc-box-padding-block-end-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
      }}
    >
      <div
        className="Polaris-BlockStack"
        style={{
          "--pc-block-stack-inline-align": "start",
          "--pc-block-stack-order": "column",
          "--pc-block-stack-gap-xs": "var(--p-space-300)",
        }}
      >
        <div
          className="Polaris-BlockStack"
          style={{
            "--pc-block-stack-inline-align": "start",
            "--pc-block-stack-order": "column",
            "--pc-block-stack-gap-xs": "var(--p-space-200)",
          }}
        >
          <p className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--semibold">
            Conversion rate
          </p>
          Percentage of sessions that resulted in online store orders, out of
          the total number of sessions.
          <div
            className="Polaris-Box"
            style={{
              "--pc-box-background": "var(--p-color-bg)",
              "--pc-box-border-radius": "var(--p-border-radius-100)",
              "--pc-box-padding-block-start-xs": "var(--p-space-200)",
              "--pc-box-padding-block-end-xs": "var(--p-space-200)",
              "--pc-box-padding-inline-start-xs": "var(--p-space-200)",
              "--pc-box-padding-inline-end-xs": "var(--p-space-200)",
              "--pc-box-width": "100%",
            }}
          >
            <p className="_Formula_zq6ol_10">
              <span className="_FormulaResult_zq6ol_16">
                Online store conversion rate
              </span>
              <span className="_FormulaBracketOrEquals_zq6ol_28">= </span>
              <span className="_FormulaBracketOrEquals_zq6ol_28">[</span>
              <span className="_FormulaVariable_zq6ol_10">converted</span>
              <span className="_FormulaVariable_zq6ol_10">sessions</span>
              <span className="_FormulaOperatorOrNumber_zq6ol_22">/</span>
              <span className="_FormulaVariable_zq6ol_10">all</span>
              <span className="_FormulaVariable_zq6ol_10">sessions</span>
              <span className="_FormulaBracketOrEquals_zq6ol_28">]</span>
              <span className="_FormulaOperatorOrNumber_zq6ol_22">*</span>
              <span className="_FormulaOperatorOrNumber_zq6ol_22">100</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      onMouseEnter={() => setPopoverActive(true)}
      onMouseLeave={() => setPopoverActive(false)}
    >
      <Popover
        activator={activator}
        active={popoverActive}
        preferredAlignment="left"
        onClose={() => setPopoverActive(false)}
        autofocusTarget="first-node"
      >
        <Popover.Pane fixed>
          <div
            style={{
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {textFieldMarkup}
          </div>
        </Popover.Pane>
      </Popover>
    </div>
  );
}

export default ConversionRate;

import dayjs from "dayjs";
import { useGlobalContext } from "../context/globalContext";

const {
  Popover,
  Button,
  InlineGrid,
  Box,
  Scrollable,
  OptionList,
  useBreakpoints,
  BlockStack,
  InlineStack,
  TextField,
  Icon,
  DatePicker,
  Divider,
} = require("@shopify/polaris");
const {
  CalendarIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} = require("@shopify/polaris-icons");
const { useState, useRef, useEffect } = require("react");

function Datepicker() {
  const { setHomeDate } = useGlobalContext();

  const [isFocusedStart, setIsFocusedStart] = useState(false);

  const handleFocusStart = () => {
    setIsFocusedStart(true);
  };

  const handleBlurStart = () => {
    setIsFocusedStart(false);
  };

  const [isFocusedEnd, setIsFocusedEnd] = useState(false);

  const handleFocusEnd = () => {
    setIsFocusedEnd(true);
  };

  const handleBlurEnd = () => {
    setIsFocusedEnd(false);
  };

  const { mdDown, lgUp } = useBreakpoints();
  const shouldShowMultiMonth = lgUp;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  const ranges = [
    {
      title: "Today",
      alias: "today",
      period: {
        since: new Date(today),
        until: new Date(today),
      },
    },
    {
      title: "Yesterday",
      alias: "yesterday",
      period: {
        since: new Date(yesterday),
        until: new Date(yesterday),
      },
    },
    {
      title: "Last 7 days",
      alias: "last7days",
      period: {
        since: new Date(today.setDate(today.getDate() - 7)),
        until: new Date(yesterday),
      },
    },
    {
      title: "Last 30 days",
      alias: "last30days",
      period: {
        since: new Date(today.setDate(today.getDate() - 30)),
        until: new Date(yesterday),
      },
    },
    {
      title: "Last 90 days",
      alias: "last90days",
      period: {
        since: new Date(today.setDate(today.getDate() - 90)),
        until: new Date(yesterday),
      },
    },
    {
      title: "Last 365 days",
      alias: "last365days",
      period: {
        since: new Date(today.setDate(today.getDate() - 365)),
        until: new Date(yesterday),
      },
    },
    {
      title: "Last month",
      alias: "lastmonth",
      period: {
        since: new Date(today.getFullYear(), today.getMonth() - 1, 1),
        until: new Date(today.getFullYear(), today.getMonth(), 0),
      },
    },
    {
      title: "Last 12 Months",
      alias: "last12months",
      period: {
        since: new Date(today.getFullYear() - 1, today.getMonth(), 1),
        until: new Date(yesterday),
      },
    },
    {
      title: "Last Year",
      alias: "lastyear",
      period: {
        since: new Date(today.getFullYear() - 1, 0, 1),
        until: new Date(
          today.getFullYear(),
          0,
          new Date(today.getFullYear(), 0).getUTCDate()
        ),
      },
    },
    {
      title: "Week to date",
      alias: "weektodate",
      period: {
        since: new Date(
          today.getDay() === 0
            ? today
            : new Date(today.setDate(today.getDate() - (today.getDay() || 7)))
        ),
        until: new Date(yesterday),
      },
    },
    {
      title: "Month to date",
      alias: "monthtodate",
      period: {
        since: new Date(today.getFullYear(), today.getMonth(), 1),
        until: new Date(yesterday),
      },
    },
    {
      title: "Quarter to Date",
      alias: "quartertodate",
      period: {
        since: new Date(
          today.getFullYear(),
          Math.floor((today.getMonth() + 3) / 3) - 1,
          1
        ),
        until: new Date(yesterday),
      },
    },
    {
      title: "Year to date",
      alias: "yeartodate",
      period: {
        since: new Date(today.getFullYear(), 0, 1),
        until: new Date(today),
      },
    },
  ];

  // Helper function to get the last date of a given month
  const getLastDateOfMonth = (year, month) => {
    return new Date(year, month + 1, 0);
  };

  // Calculate quarters
  const quarters = [
    {
      title: " 1st Quarter (2024)",
      alias: "q1",
      period: {
        since: new Date(today.getFullYear(), 0, 1),
        until: getLastDateOfMonth(today.getFullYear(), 2),
      },
    },
    {
      title: "4th Quarter (2023)",
      alias: "q4",
      period: {
        since: new Date(today.getFullYear(), 9, 1),
        until: getLastDateOfMonth(today.getFullYear(), 11),
      },
    },
    {
      title: "3rd Quarter (2023)",
      alias: "q3",
      period: {
        since: new Date(today.getFullYear(), 6, 1),
        until: getLastDateOfMonth(today.getFullYear(), 8),
      },
    },
    {
      title: "2nd Quarter (2023)",
      alias: "q2",
      period: {
        since: new Date(today.getFullYear(), 3, 1),
        until: getLastDateOfMonth(today.getFullYear(), 5),
      },
    },
  ];

  const bfcm = [
    { title: "BFCM (2023)", alias: "bfcm_2023", period: calculateBFCM(2023) },
    { title: "BFCM (2022)", alias: "bfcm_2022", period: calculateBFCM(2022) },
    { title: "BFCM (2021)", alias: "bfcm_2021", period: calculateBFCM(2021) },
    { title: "BFCM (2020)", alias: "bfcm_2020", period: calculateBFCM(2020) },
    { title: "BFCM (2019)", alias: "bfcm_2019", period: calculateBFCM(2019) },
    { title: "BFCM (2018)", alias: "bfcm_2018", period: calculateBFCM(2018) },
  ];

  const [popoverActive, setPopoverActive] = useState(false);
  const [activeDateRange, setActiveDateRange] = useState(ranges[2]);

  const valueButton =
    activeDateRange.title === "Custom"
      ? activeDateRange.period.since.toDateString() +
        " - " +
        activeDateRange.period.until.toDateString()
      : activeDateRange.title;

  const [buttonValue, setbuttonValue] = useState("Last 7 days");

  const [inputValues, setInputValues] = useState({});

  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear(),
  });

  const [quartersExpanded, setQuartersExpanded] = useState(false);
  const [bfcmExpanded, setBfcmExpanded] = useState(false);
  const datePickerRef = useRef(null);
  const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;
  function isDate(date) {
    return !isNaN(new Date(date).getDate());
  }
  function isValidYearMonthDayDateString(date) {
    return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
  }
  function isValidDate(date) {
    return date.length === 10 && isValidYearMonthDayDateString(date);
  }
  function parseYearMonthDayDateString(input) {
    // Date-only strings (e.g. "1970-01-01") are treated as UTC, not local time
    // when using new Date()
    // We need to split year, month, day to pass into new Date() separately
    // to get a localized Date
    const [year, month, day] = input.split("-");
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  function formatDateToYearMonthDayDateString(date) {
    const year = String(date.getFullYear());
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    if (month.length < 2) {
      month = String(month).padStart(2, "0");
    }
    if (day.length < 2) {
      day = String(day).padStart(2, "0");
    }
    return [year, month, day].join("-");
  }
  function formatDate(date) {
    return formatDateToYearMonthDayDateString(date);
  }
  function nodeContainsDescendant(rootNode, descendant) {
    if (rootNode === descendant) {
      return true;
    }
    let parent = descendant.parentNode;
    while (parent != null) {
      if (parent === rootNode) {
        return true;
      }
      parent = parent.parentNode;
    }
    return false;
  }
  function isNodeWithinPopover(node) {
    return datePickerRef?.current
      ? nodeContainsDescendant(datePickerRef.current, node)
      : false;
  }

  function handleStartInputValueChange(value) {
    setInputValues((prevState) => {
      return { ...prevState, since: value };
    });

    if (isValidDate(value)) {
      const newSince = parseYearMonthDayDateString(value);
      setActiveDateRange((prevState) => {
        const newPeriod =
          prevState.period && newSince <= prevState.period.until
            ? { since: newSince, until: prevState.period.until }
            : { since: newSince, until: newSince };

        return {
          ...prevState,
          alias: "custom",
          title: "Custom",
          period: newPeriod,
        };
      });
    }
  }

  function handleEndInputValueChange(value) {
    setInputValues((prevState) => ({ ...prevState, until: value }));

    if (isValidDate(value)) {
      const newUntil = parseYearMonthDayDateString(value);

      setActiveDateRange((prevState) => {
        const newPeriod =
          prevState && prevState.period && newUntil >= prevState.period.since
            ? { since: prevState.period.since, until: newUntil }
            : { since: newUntil, until: newUntil };

        return {
          ...prevState,
          alias: "custom",
          title: "Custom",
          period: newPeriod,
        };
      });
    }
  }

  function handleInputBlur({ relatedTarget }) {
    const isRelatedTargetWithinPopover =
      relatedTarget != null && isNodeWithinPopover(relatedTarget);
    // If focus moves from the TextField to the Popover
    // we don't want to close the popover
    if (isRelatedTargetWithinPopover) {
      return;
    }
    setPopoverActive(false);
  }

  function handleMonthChange(month, year) {
    setDate({ month, year });
  }

  function handleCalendarChange({ start, end }) {
    const newDateRange = ranges.find((range) => {
      return (
        range.period.since.valueOf() === start.valueOf() &&
        range.period.until.valueOf() === end.valueOf()
      );
    }) || {
      alias: "custom",
      title: "Custom",
      period: {
        since: start,
        until: end,
      },
    };

    setActiveDateRange(newDateRange);
  }

  function apply() {
    setbuttonValue(valueButton);
    setHomeDate(inputValues);
    setPopoverActive(false);
  }

  function cancel() {
    setPopoverActive(false);
  }

  useEffect(() => {
    if (activeDateRange) {
      setInputValues({
        since: formatDate(activeDateRange.period.since),
        until: formatDate(activeDateRange.period.until),
      });
      function monthDiff(referenceDate, newDate) {
        return (
          newDate.month -
          referenceDate.month +
          12 * (referenceDate.year - newDate.year)
        );
      }
      const monthDifference = monthDiff(
        { year, month },
        {
          year: activeDateRange.period.until.getFullYear(),
          month: activeDateRange.period.until.getMonth(),
        }
      );
      if (monthDifference > 1 || monthDifference < 0) {
        setDate({
          month: activeDateRange.period.until.getMonth(),
          year: activeDateRange.period.until.getFullYear(),
        });
      }
    }
  }, [activeDateRange]);

  function calculateBFCM(year) {
    const since = new Date(`${year}-11-25`);
    const until = new Date(`${year}-11-30`);
    return { since, until };
  }

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      captureOverscroll
      // fullHeight
      activator={
        <Button
          size="slim"
          icon={CalendarIcon}
          onClick={() => setPopoverActive(!popoverActive)}
        >
          {buttonValue}
        </Button>
      }
      onClose={() => setPopoverActive(false)}
    >
      <Scrollable>
        {/* <Popover.Pane fixed> */}
        <InlineGrid
          columns={{
            xs: "1fr",
            sm: "1fr",
            md: "max-content max-content",
            lg: "minmax(160px, 210px) minmax(520px, 1fr)",
          }}
          gap={0}
          ref={datePickerRef}
        >
          <Box
          // maxWidth={mdDown ? "516px" : "212px"}
          // width={mdDown ? "100%" : "212px"}
          // padding={{ xs: 500, md: 0 }}
          // paddingBlockEnd={{ xs: 100, md: 0 }}
          >
            <Scrollable
              style={{
                height: "330px",
                // boxShadow: "0px 8px 16px -4px rgba(26, 26, 26, 0.22)",
              }}
            >
              <OptionList
                onChange={(value) =>
                  setActiveDateRange(
                    ranges.find((range) => range.alias === value[0])
                  )
                }
                options={ranges.map((range) => ({
                  value: range.alias,
                  label: range.title,
                }))}
                selected={[activeDateRange.alias]}
              />
              <div
                style={{ margin: "0px 4px 4px 4px", padding: "8px" }}
                // padding="8"
                onClick={() => setQuartersExpanded(!quartersExpanded)}
              >
                <InlineStack direction={"row"} align="center">
                  <span style={{ cursor: "pointer", flexGrow: 1 }}>
                    Quarters
                  </span>
                  <Icon
                    source={
                      quartersExpanded ? ChevronDownIcon : ChevronRightIcon
                    }
                  />
                </InlineStack>
              </div>
              {quartersExpanded && (
                <OptionList
                  onChange={(value) =>
                    setActiveDateRange(
                      quarters.find((quarter) => quarter.alias === value[0])
                    )
                  }
                  options={quarters.map((quarter) => ({
                    value: quarter.alias,
                    label: quarter.title,
                  }))}
                  selected={[activeDateRange.alias]}
                />
              )}
              <div
                style={{
                  cursor: "pointer",
                  margin: "0px 4px",
                  padding: "8px",
                  flexGrow: 1,
                }}
                onClick={() => setBfcmExpanded(!bfcmExpanded)}
              >
                <InlineStack direction={"row"} align="center" wrap={false}>
                  <span style={{ cursor: "pointer", flexGrow: 1 }}>
                    <div>Black Friday Cyber Monday</div>
                  </span>
                  <Icon
                    source={bfcmExpanded ? ChevronDownIcon : ChevronRightIcon}
                  />
                </InlineStack>
              </div>
              {bfcmExpanded && (
                <OptionList
                  onChange={(value) =>
                    setActiveDateRange(
                      bfcm.find((event) => event.alias === value[0])
                    )
                  }
                  options={bfcm.map((event) => ({
                    value: event.alias,
                    label: event.title,
                  }))}
                  selected={[activeDateRange.alias]}
                />
              )}
            </Scrollable>
          </Box>

          <Box padding={{ xs: 400 }} maxWidth={mdDown ? "320px" : "516px"}>
            <BlockStack gap="400">
              <InlineStack gap="200">
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    role="combobox"
                    label={"Since"}
                    onFocus={handleFocusStart}
                    onBlur={handleBlurStart}
                    value={
                      isFocusedStart
                        ? inputValues.since
                        : dayjs(inputValues?.since).format("MMM DD, YYYY")
                    }
                    labelHidden
                    prefix={<Icon source={CalendarIcon} />}
                    onChange={handleStartInputValueChange}
                    autoComplete="off"
                  />
                </div>

                <Icon source={ArrowRightIcon} />
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    role="combobox"
                    label={"Until"}
                    labelHidden
                    onFocus={handleFocusEnd}
                    until
                    onBlur={handleBlurEnd}
                    value={
                      isFocusedEnd
                        ? inputValues?.until
                        : dayjs(inputValues?.until).format("MMM DD, YYYY")
                    }
                    prefix={<Icon source={CalendarIcon} />}
                    onChange={handleEndInputValueChange}
                    autoComplete="off"
                  />
                </div>
              </InlineStack>
              <div>
                <DatePicker
                  month={month}
                  year={year}
                  selected={{
                    start: activeDateRange.period.since,
                    end: activeDateRange.period.until,
                  }}
                  onMonthChange={handleMonthChange}
                  onChange={handleCalendarChange}
                  multiMonth={shouldShowMultiMonth}
                  allowRange
                />
              </div>
            </BlockStack>
          </Box>
        </InlineGrid>
        <Divider />
      </Scrollable>
      <div
        className="Polaris-Box"
        style={{
          "--pc-box-padding-block-start-xs": "var(--p-space-400)",
          "--pc-box-padding-block-end-xs": "var(--p-space-400)",
          "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
          "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
        }}
      >
        <div
          className="Polaris-InlineStack"
          style={{
            "--pc-inline-stack-align": "end",
            "--pc-inline-stack-wrap": "wrap",
            "--pc-inline-stack-gap-xs": "var(--p-space-200)",
            "--pc-inline-stack-flex-direction-xs": "row",
          }}
        >
          <button
            className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
            type="button"
            onClick={cancel}
          >
            <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
              Cancel
            </span>
          </button>
          <button
            className={`Polaris-Button Polaris-Button--pressable Polaris-Button--variantPrimary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter`}
            type="button"
            tabIndex="-1"
            onClick={apply}
          >
            <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
              Apply
            </span>
          </button>
        </div>
      </div>
    </Popover>
  );
}
export default Datepicker;

import "@shopify/polaris/build/esm/styles.css";
import React from "react";
import ReactDOM from "react-dom";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";

import App from "./App";
import "./index.css";
import "./render.css";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./App/store";
import { GlobalProvider } from "./context/globalContext";

function WrappedApp() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <Provider store={store}>
          <AppProvider i18n={enTranslations}>
            <App />
          </AppProvider>
        </Provider>
      </GlobalProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<WrappedApp />, document.getElementById("root"));

import React from "react";

const DashboardCards = () => {
  return (
    <>

      {/* Mile Stone Award */}
      <div class="_MilestoneAwardCelebration_uy308_7 _Loading_uy308_3"></div>
      {/* Home Divider */}
      <div class="_homeDivider_1xikp_3">
        <h2 class="Polaris-Text--root Polaris-Text--headingSm">
          Things to do next
        </h2>
      </div>
      {/* Tasks Container */}
      <div class="_TasksContainer_iiban_3">
        <div
          class="Polaris-Grid"
          style={{
            "--pc-grid-gap-md": "var(--p-space-200)",
            "--pc-grid-gap-lg": "var(--p-space-200)",
            "--pc-grid-gap-xl": "var(--p-space-200)",
            "--pc-grid-columns-md": 2,
            "--pc-grid-columns-lg": 3,
            "--pc-grid-columns-xl": 3,
          }}
        >
          <a
            data-polaris-unstyled="true"
            class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeLarge Polaris-Button--textAlignLeft Polaris-Button--fullWidth Polaris-Button--iconWithText"
            href="/store/essentialss-pk/orders?financial_status=pending%2Cauthorized%2Cpaid%2Cpartially_paid%2Cpartially_refunded&amp;fulfillment_status=unshipped%2Cpartial&amp;status=open"
          >
            <span class="Polaris-Button__Icon">
              <div class="_Image_h0o6t_1">
                <img
                  alt=""
                  src="https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/tasks/next-orders-14465c8905b5d7fa91709569cae38538bf2ef22c98c359d657ddd13fbd926de1.svg"
                />
              </div>
            </span>
            <span class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium">
              1 order to fulfill
            </span>
          </a>
          <a
            data-polaris-unstyled="true"
            class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeLarge Polaris-Button--textAlignLeft Polaris-Button--fullWidth Polaris-Button--iconWithText"
            href="/store/essentialss-pk/orders?financial_status=pending%2Cauthorized&amp;status=open"
          >
            <span class="Polaris-Button__Icon">
              <div class="_Image_h0o6t_1">
                <img
                  alt=""
                  src="https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/tasks/next-payments-c760a8e787e405c2e8910c55355896991041e6ffce49f2b8c3c660d857ab3b94.svg"
                />
              </div>
            </span>
            <span class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium">
              50+ payments to capture
            </span>
          </a>
        </div>
      </div>
      {/* Feed container */}
      <div class="_FeedContainer_1qxrp_3">
        <div class="_Feed_qbmy0_1">
          <ul class="_CardList_qbmy0_5">
            <li>
              <div
                class="_CardWrapper_8v8kf_20"
                id="gid://shopify/HomeCard/summer_editions24_seller_card?analytics_group=FeedCard"
              >
                <div class="_OverflowMenu_8v8kf_3">
                  <div>
                    <div class="_ButtonContainer_njmna_1">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        type="button"
                        tabindex="0"
                        aria-controls=":rbm:"
                        aria-owns=":rbm:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="1 1 18 18"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="Polaris-ShadowBevel"
                    style={{
                      "--pc-shadow-bevel-z-index": 32,
                      "--pc-shadow-bevel-content-xs": "",
                      "--pc-shadow-bevel-box-shadow-xs": "var(--p-shadow-100)",
                      "--pc-shadow-bevel-border-radius-xs":
                        "var(--p-border-radius-300)",
                    }}
                  >
                    <div
                      className="Polaris-Box"
                      style={{
                        "--pc-box-background": "var(--p-color-bg-surface)",
                        "--pc-box-min-height": "100%",
                        "--pc-box-overflow-x": "clip",
                        "--pc-box-overflow-y": "clip",
                        "--pc-box-padding-block-start-xs": "var(--p-space-0)",
                        "--pc-box-padding-block-end-xs": "var(--p-space-0)",
                        "--pc-box-padding-inline-start-xs": "var(--p-space-0)",
                        "--pc-box-padding-inline-end-xs": "var(--p-space-0)",
                      }}
                    >
                      <div className="_container_1rdfx_1">
                        <div
                          className="Polaris-InlineGrid"
                          style={{
                            "--pc-inline-grid-grid-template-columns-xs":
                              "repeat(2, minmax(0, 1fr))",
                            "--pc-inline-grid-gap-xs": "var(--p-space-200)",
                            "--pc-inline-grid-align-items": "center",
                          }}
                        >
                          <div
                            className="Polaris-BlockStack"
                            style={{
                              "--pc-block-stack-align": "space-between",
                              "--pc-block-stack-order": "column",
                              "--pc-block-stack-gap-xs": "var(--p-space-400)",
                            }}
                          >
                            <img
                              alt=""
                              src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/logo-CYv6K1S67n36.svg"
                              className="_logo_1rdfx_22"
                            />
                            <p className="Polaris-Text--root Polaris-Text--bold">
                              150+ new features to grow your business
                            </p>
                            <div className="Polaris-ButtonGroup">
                              <div className="Polaris-ButtonGroup__Item">
                                <a
                                  href="https://www.shopify.com/editions/summer2024?utm_source=admin&utm_medium=referrer&utm_campaign=summer24edition-launch&utm_content=s24-homecard"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  data-polaris-unstyled="true"
                                  className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPrimary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                >
                                  <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--semibold">
                                    See what's new
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="Polaris-InlineStack"
                            style={{
                              "--pc-inline-stack-align": "end",
                              "--pc-inline-stack-wrap": "wrap",
                              "--pc-inline-stack-flex-direction-xs": "row",
                            }}
                          >
                            <img
                              alt=""
                              src="https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/editions/editions-16d0521441ae785fbbc90c7b248ba9b52cb6b49dd0327693fb0ae5a610853189.png"
                              className="_img_1rdfx_12"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <div
                class="_CardWrapper_8v8kf_20"
                id="gid://shopify/HomeCard/tap_to_pay_iphone?analytics_group=FeedCard"
              >
                <div class="_OverflowMenu_8v8kf_3">
                  <div>
                    <div class="_ButtonContainer_njmna_1">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        type="button"
                        tabindex="0"
                        aria-controls=":rbm:"
                        aria-owns=":rbm:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="Polaris-LegacyCard">
                    <div class="_CardLayout_1psia_1">
                      <div class="_CardContent_1psia_12">
                        <div class="">
                          <div class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                            <div class="_ContentContainer_j6sgc_1">
                              <div class="Polaris-TextContainer Polaris-TextContainer--spacingTight">
                                <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                  <span>
                                    Build trust with customers in person
                                  </span>
                                </h2>
                                <p>
                                  Make connections and gather valuable feedback
                                  by selling at pop-ups or to friends and family
                                  with Point of Sale and Tap to Pay on iPhone.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="_Footer_1s023_1">
                          <div class="Polaris-LegacyStack Polaris-LegacyStack--distributionLeading Polaris-LegacyStack--alignmentTrailing Polaris-LegacyStack--noWrap">
                            <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                              <div class="Polaris-ButtonGroup">
                                <div class="Polaris-ButtonGroup__Item">
                                  <a
                                    data-polaris-unstyled="true"
                                    id="tap_to_pay_iphone_primary_action"
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                    href="/store/essentialss-pk/apps/point-of-sale-channel"
                                  >
                                    <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                      Set up Point of Sale
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="_CardImage_1psia_16">
                        <img
                          alt=""
                          src="https://cdn.shopify.com/b/shopify-guidance-dashboard-public/8ae127sobs875ascb7odu0bgl12n.png"
                          class="_Illustration_1psia_35"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                class="_CardWrapper_8v8kf_20"
                id="gid://shopify/HomeCard/product_reviews?analytics_group=FeedCard"
              >
                <div class="_OverflowMenu_8v8kf_3">
                  <div>
                    <div class="_ButtonContainer_njmna_1">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        type="button"
                        tabindex="0"
                        aria-controls=":rbo:"
                        aria-owns=":rbo:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="Polaris-LegacyCard">
                    <div class="_CardLayout_1psia_1">
                      <div class="_CardContent_1psia_12">
                        <div class="">
                          <div class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                            <div class="_ContentContainer_j6sgc_1">
                              <div class="Polaris-TextContainer Polaris-TextContainer--spacingTight">
                                <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                  <span>
                                    Show product reviews in more places
                                  </span>
                                </h2>
                                <p>
                                  Let shoppers see how great your products are
                                  with apps that let you show customer reviews
                                  everywhere—from your online store to the Shop
                                  app.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="_Footer_1s023_1">
                          <div class="Polaris-LegacyStack Polaris-LegacyStack--distributionLeading Polaris-LegacyStack--alignmentTrailing Polaris-LegacyStack--noWrap">
                            <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                              <div class="Polaris-ButtonGroup">
                                <div class="Polaris-ButtonGroup__Item">
                                  <a
                                    href="https://apps.shopify.com/stories/guide-product-reviews?st_campaign=admin-home-card&amp;st_source=admin&amp;utm_campaign=admin-home-card&amp;utm_source=admin"
                                    rel="noopener noreferrer"
                                    target="_self"
                                    data-polaris-unstyled="true"
                                    id="product_reviews_primary_action"
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                  >
                                    <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                      View app guide
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="_CardImage_1psia_16">
                        <img
                          alt=""
                          src="https://cdn.shopify.com/b/shopify-guidance-dashboard-public/tcrwpgpmnvrxg6mevjucccpijmfz.png"
                          class="_Illustration_1psia_35"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                class="_CardWrapper_8v8kf_20"
                id="gid://shopify/HomeCard/example_presellers?analytics_group=FeedCard"
              >
                <div class="_OverflowMenu_8v8kf_3">
                  <div>
                    <div class="_ButtonContainer_njmna_1">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        type="button"
                        tabindex="0"
                        aria-controls=":rbq:"
                        aria-owns=":rbq:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="Polaris-LegacyCard">
                    <div class="_CardLayout_1psia_1">
                      <div class="_CardContent_1psia_12">
                        <div class="">
                          <div class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                            <div class="_ContentContainer_j6sgc_1">
                              <div class="Polaris-TextContainer Polaris-TextContainer--spacingTight">
                                <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                  <span>
                                    Get inspired to launch your business
                                  </span>
                                </h2>
                                <p>
                                  Browse existing Shopify stores to inspire your
                                  own approach to building a brand and launching
                                  a business that stands out.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="_Footer_1s023_1">
                          <div class="Polaris-LegacyStack Polaris-LegacyStack--distributionLeading Polaris-LegacyStack--alignmentTrailing Polaris-LegacyStack--noWrap">
                            <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                              <div class="Polaris-ButtonGroup">
                                <div class="Polaris-ButtonGroup__Item">
                                  <a
                                    href="https://www.shopify.com/examples"
                                    rel="noopener noreferrer"
                                    target="_self"
                                    data-polaris-unstyled="true"
                                    id="example_presellers_primary_action"
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                  >
                                    <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                      View examples
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="_CardImage_1psia_16">
                        <img
                          alt=""
                          src="https://cdn.shopify.com/b/shopify-guidance-dashboard-public/n4aunmlobzbq0ln7bk2dnhm305da.png"
                          class="_Illustration_1psia_35"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                class="_CardWrapper_8v8kf_20"
                id="gid://shopify/HomeCard/new_business?analytics_group=FeedCard"
              >
                <div class="_OverflowMenu_8v8kf_3">
                  <div>
                    <div class="_ButtonContainer_njmna_1">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        type="button"
                        tabindex="0"
                        aria-controls=":rbs:"
                        aria-owns=":rbs:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="Polaris-LegacyCard">
                    <div class="_CardLayout_1psia_1">
                      <div class="_CardContent_1psia_12">
                        <div class="">
                          <div class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                            <div class="_ContentContainer_j6sgc_1">
                              <div class="Polaris-TextContainer Polaris-TextContainer--spacingTight">
                                <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                  <span>
                                    Increase visibility and conversion with apps
                                  </span>
                                </h2>
                                <p>
                                  Give your new business a head start with apps
                                  that let you find new customers, increase
                                  brand loyalty, create marketing campaigns, and
                                  offer customer support.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="_Footer_1s023_1">
                          <div class="Polaris-LegacyStack Polaris-LegacyStack--distributionLeading Polaris-LegacyStack--alignmentTrailing Polaris-LegacyStack--noWrap">
                            <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                              <div class="Polaris-ButtonGroup">
                                <div class="Polaris-ButtonGroup__Item">
                                  <a
                                    href="https://apps.shopify.com/stories/guide-build-a-business?st_campaign=admin-home-card&amp;st_source=admin&amp;utm_campaign=admin-home-card&amp;utm_source=admin"
                                    rel="noopener noreferrer"
                                    target="_self"
                                    data-polaris-unstyled="true"
                                    id="new_business_primary_action"
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                  >
                                    <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                      View app guide
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="_CardImage_1psia_16">
                        <img
                          alt=""
                          src="https://cdn.shopify.com/b/shopify-guidance-dashboard-public/xwrnuiqsqajp8f60jw68xy0xtcn0.png"
                          class="_Illustration_1psia_35"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                class="_CardWrapper_8v8kf_20"
                id="gid://shopify/HomeCard/install_google_youtube_app?analytics_group=FeedCard"
              >
                <div class="_OverflowMenu_8v8kf_3">
                  <div>
                    <div class="_ButtonContainer_njmna_1">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeSlim Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                        type="button"
                        tabindex="0"
                        aria-controls=":rbu:"
                        aria-owns=":rbu:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                              <path d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="Polaris-LegacyCard">
                    <div class="_CardLayout_1psia_1">
                      <div class="_CardContent_1psia_12">
                        <div class="">
                          <div class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                            <div class="_ContentContainer_j6sgc_1">
                              <div class="Polaris-TextContainer Polaris-TextContainer--spacingTight">
                                <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                  <span>
                                    Attract customers on Google for free
                                  </span>
                                </h2>
                                <p>
                                  Increase your product visibility and turn more
                                  shoppers into customers by listing your
                                  products for free on Google Search, YouTube,
                                  Shopping, and Images.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="_Footer_1s023_1">
                          <div class="Polaris-LegacyStack Polaris-LegacyStack--distributionLeading Polaris-LegacyStack--alignmentTrailing Polaris-LegacyStack--noWrap">
                            <div class="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                              <div class="Polaris-ButtonGroup">
                                <div class="Polaris-ButtonGroup__Item">
                                  <a
                                    href="https://apps.shopify.com/login/authenticate?login_hint=essentailss11%40gmail.com&amp;shop=essentialss-pk&amp;url=https%3A%2F%2Fapps.shopify.com%2Fgoogle&amp;utm_campaign=app_store_redirect&amp;utm_content=contextual&amp;utm_medium=shopify&amp;utm_source=admin"
                                    rel="noopener noreferrer"
                                    target="_self"
                                    data-polaris-unstyled="true"
                                    id="install_google_youtube_app_primary_action"
                                    class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                  >
                                    <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                      Install the Google &amp; YouTube app
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="_CardImage_1psia_16">
                        <img
                          alt=""
                          src="https://cdn.shopify.com/b/shopify-guidance-dashboard-public/41l4igfaiipywq6kr7ttxbn46m00.png"
                          class="_Illustration_1psia_35"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Container */}

      <div className="_Container_mf6ou_10">
        <hr
          className="Polaris-Divider"
          style={{
            borderBlockStart:
              "var(--p-border-width-025) solid var(--p-color-border-tertiary)",
          }}
        />
        <div className="_Text_mf6ou_17">
          <div
            className="Polaris-InlineStack"
            style={{
              "--pc-inline-stack-align": "center",
              "--pc-inline-stack-wrap": "wrap",
              "--pc-inline-stack-flex-direction-xs": "row",
            }}
          >
            <div
              className="Polaris-Box"
              style={{
                "--pc-box-background": "var(--p-color-bg)",
                "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
                "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
              }}
            >
              <div
                className="Polaris-InlineStack"
                style={{
                  "--pc-inline-stack-align": "center",
                  "--pc-inline-stack-wrap": "wrap",
                  "--pc-inline-stack-gap-xs": "var(--p-space-200)",
                  "--pc-inline-stack-flex-direction-xs": "row",
                }}
              >
                <div className="Polaris-Box">
                  <span className="Polaris-Icon Polaris-Icon--toneSubdued">
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M13.28 9.03a.75.75 0 0 0-1.06-1.06l-2.97 2.97-1.22-1.22a.75.75 0 0 0-1.06 1.06l1.75 1.75a.75.75 0 0 0 1.06 0l3.5-3.5Z"></path>
                      <path
                        fillRule="evenodd"
                        d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <h2 className="Polaris-Text--root Polaris-Text--headingSm Polaris-Text--block Polaris-Text--center Polaris-Text--subdued">
                  All caught up
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Container */}
      <div className="_Container_1eg1m_3">
        <div>
          <div
            className="Polaris-ShadowBevel"
            style={{
              "--pc-shadow-bevel-z-index": 32,
              "--pc-shadow-bevel-content-xs": '""',
              "--pc-shadow-bevel-box-shadow-xs": "var(--p-shadow-100)",
              "--pc-shadow-bevel-border-radius-xs":
                "var(--p-border-radius-300)",
            }}
          >
            <div
              className="Polaris-Box"
              style={{
                "--pc-box-background": "var(--p-color-bg-surface)",
                "--pc-box-min-height": "100%",
                "--pc-box-overflow-x": "clip",
                "--pc-box-overflow-y": "clip",
                "--pc-box-padding-block-start-xs": "var(--p-space-0)",
                "--pc-box-padding-block-end-xs": "var(--p-space-0)",
                "--pc-box-padding-inline-start-xs": "var(--p-space-0)",
                "--pc-box-padding-inline-end-xs": "var(--p-space-0)",
              }}
            >
              <div
                className="Polaris-Box"
                style={{
                  "--pc-box-padding-block-start-xs": "var(--p-space-400)",
                  "--pc-box-padding-block-end-xs": "var(--p-space-0)",
                  "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
                  "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
                }}
              >
                <div
                  className="Polaris-Box"
                  style={{
                    "--pc-box-background":
                      "var(--p-color-bg-surface-secondary)",
                    "--pc-box-border-radius": "var(--p-border-radius-200)",
                    "--pc-box-overflow-x": "hidden",
                    "--pc-box-overflow-y": "hidden",
                    "--pc-box-padding-block-start-xs": "var(--p-space-400)",
                    "--pc-box-padding-block-end-xs": "var(--p-space-400)",
                    "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
                    "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
                  }}
                >
                  <div className="Polaris-Grid">
                    <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_4ColumnXs Polaris-Grid-Cell--cell_3ColumnSm Polaris-Grid-Cell--cell_4ColumnMd Polaris-Grid-Cell--cell_8ColumnLg Polaris-Grid-Cell--cell_8ColumnXl">
                      <div
                        className="Polaris-BlockStack"
                        style={{
                          "--pc-block-stack-inline-align": "start",
                          "--pc-block-stack-order": "column",
                          "--pc-block-stack-gap-xs": "var(--p-space-300)",
                        }}
                      >
                        <div
                          className="Polaris-BlockStack"
                          style={{
                            "--pc-block-stack-order": "column",
                            "--pc-block-stack-gap-xs": "var(--p-space-100)",
                          }}
                        >
                          <h3 className="Polaris-Text--root Polaris-Text--headingSm">
                            Discover more of Shopify
                          </h3>
                          <p className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--subdued">
                            Browse features, apps, and sales channels to grow
                            your business
                          </p>
                        </div>
                        <button
                          className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                          type="button"
                        >
                          <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                            Explore now
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_2ColumnXs Polaris-Grid-Cell--cell_3ColumnSm Polaris-Grid-Cell--cell_2ColumnMd Polaris-Grid-Cell--cell_4ColumnLg Polaris-Grid-Cell--cell_4ColumnXl">
                      <div
                        className="Polaris-Box"
                        style={{ position: "relative" }}
                      >
                        <img
                          src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/productCatalogBg-DYpCd6X0Dq6_.png"
                          alt="Browse features, apps, and sales channels to grow your business"
                          className="_Image_gf9nv_1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Polaris-Box"
                style={{
                  "--pc-box-overflow-x": "hidden",
                  "--pc-box-overflow-y": "hidden",
                  "--pc-box-padding-block-start-xs": "var(--p-space-400)",
                  "--pc-box-padding-block-end-xs": "var(--p-space-400)",
                  "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
                  "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
                }}
              >
                <div
                  className="Polaris-Grid"
                  style={{
                    "--pc-grid-columns-xs": 1,
                    "--pc-grid-columns-sm": 1,
                    "--pc-grid-columns-md": 12,
                  }}
                >
                  <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_1ColumnXs Polaris-Grid-Cell--cell_1ColumnSm Polaris-Grid-Cell--cell_4ColumnMd">
                    <button
                      className="_ValuePropFlexWrapper_gf9nv_30"
                      type="button"
                    >
                      <div
                        className="Polaris-BlockStack"
                        style={{
                          "--pc-block-stack-order": "column",
                          "--pc-block-stack-gap-xs": "var(--p-space-100)",
                        }}
                      >
                        <h3 className="Polaris-Text--root Polaris-Text--headingSm">
                          Drive traffic
                        </h3>
                        <p className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--subdued">
                          Use marketing tools to attract more potential
                          customers.
                        </p>
                      </div>
                    </button>
                  </div>
                  <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_1ColumnXs Polaris-Grid-Cell--cell_1ColumnSm Polaris-Grid-Cell--cell_4ColumnMd">
                    <button
                      className="_ValuePropFlexWrapper_gf9nv_30"
                      type="button"
                    >
                      <div
                        className="Polaris-BlockStack"
                        style={{
                          "--pc-block-stack-order": "column",
                          "--pc-block-stack-gap-xs": "var(--p-space-100)",
                        }}
                      >
                        <h3 className="Polaris-Text--root Polaris-Text--headingSm">
                          Improve conversion
                        </h3>
                        <p className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--subdued">
                          Convert more customers faster with built-in tools.
                        </p>
                      </div>
                    </button>
                  </div>
                  <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_1ColumnXs Polaris-Grid-Cell--cell_1ColumnSm Polaris-Grid-Cell--cell_4ColumnMd">
                    <button
                      className="_ValuePropFlexWrapper_gf9nv_30"
                      type="button"
                    >
                      <div
                        className="Polaris-BlockStack"
                        style={{
                          "--pc-block-stack-order": "column",
                          "--pc-block-stack-gap-xs": "var(--p-space-100)",
                        }}
                      >
                        <h3 className="Polaris-Text--root Polaris-Text--headingSm">
                          Increase order value
                        </h3>
                        <p className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--subdued">
                          Boost sales by expanding to new channels, offering
                          bundles, and more.
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCards;

import React from "react";

const Headorder = () => {
  return (
    <div
      class="Polaris-Box"
      style={{
        "--pc-box-padding-block-start-xs": "var(--p-space-400)",
        "--pc-box-padding-block-start-md": "var(--p-space-600)",
        "--pc-box-padding-block-end-xs": "var(--p-space-400)",
        "--pc-box-padding-block-end-md": "var(--p-space-600)",
        "--pc-box-padding-inline-start-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-start-sm": "var(--p-space-0)",
        "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
        "--pc-box-padding-inline-end-sm": "var(--p-space-0)",
        position: "relative",
      }}
    >
      <div role="status">
        <p class="Polaris-Text--root Polaris-Text--visuallyHidden">
          Orders. This page is ready
        </p>
      </div>
      <div class="Polaris-Page-Header--noBreadcrumbs Polaris-Page-Header--mediumTitle">
        <div class="Polaris-Page-Header__Row">
          <div class="Polaris-Page-Header__TitleWrapper">
            <div class="Polaris-Header-Title__TitleWrapper">
              <h1 class="Polaris-Header-Title">
                <span class="Polaris-Text--root Polaris-Text--headingLg Polaris-Text--bold">
                  Orders
                </span>
              </h1>
            </div>
          </div>
          <div class="Polaris-Page-Header__RightAlign">
            <div class="Polaris-ActionMenu">
              <div class="Polaris-ActionMenu-Actions__ActionsLayoutOuter">
                <div class="Polaris-ActionMenu-Actions__ActionsLayoutMeasurer">
                  <div class="Polaris-ActionMenu-SecondaryAction">
                    <button
                      class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                      type="button"
                    >
                      <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                        Export
                      </span>
                    </button>
                  </div>
                  <div class="Polaris-ActionMenu-SecondaryAction">
                    <button
                      class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--disclosure"
                      type="button"
                    >
                      <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                        More actions
                      </span>
                      <span class="Polaris-Button__Icon">
                        <span class="Polaris-Icon">
                          <svg
                            viewBox="0 0 20 20"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div class="Polaris-ActionMenu-SecondaryAction">
                    <button
                      class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--disclosure"
                      type="button"
                    >
                      <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                        More actions
                      </span>
                      <span class="Polaris-Button__Icon">
                        <span class="Polaris-Icon">
                          <svg
                            viewBox="0 0 20 20"
                            class="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="Polaris-ActionMenu-Actions__ActionsLayout">
                  <div class="Polaris-ActionMenu-SecondaryAction">
                    <button
                      class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                      type="button"
                    >
                      <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                        Export
                      </span>
                    </button>
                  </div>
                  <div>
                    <div class="Polaris-ActionMenu-SecondaryAction">
                      <button
                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--disclosure"
                        type="button"
                        tabindex="0"
                        aria-controls=":r24:"
                        aria-owns=":r24:"
                        aria-expanded="false"
                        data-state="closed"
                      >
                        <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                          More actions
                        </span>
                        <span class="Polaris-Button__Icon">
                          <span class="Polaris-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Polaris-Page-Header__PrimaryActionWrapper">
              <div class="Polaris-Box Polaris-Box--printHidden">
                <a
                  data-polaris-unstyled="true"
                  class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPrimary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                  href="/store/essentialss-pk/draft_orders/new"
                >
                  <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                    Create order
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headorder;

import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import "./styles/home.css";
import "./styles/constantn3w.css";
import "./styles/matricCard.css";
import "./styles/popover.css";
import "./styles/homeChart.css";
import "./styles/analytics.css";
import "./styles/constantvL5.css";
import "./styles/main.css";
import "./styles/overView.css";
import "./styles/constantlrl.css";
import "./styles/matricCardwnp.css";

const App = () => {
  return (
    <div>
      <Navbar />
    </div>
  );
};

export default App;

import dayjs from "dayjs";
import React, { useContext, useState } from "react";

const AppContext = React.createContext();
const GlobalProvider = ({ children }) => {
  const [homeDate, setHomeDate] = useState({
    since: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    until: dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
  });

  const [analyticDate, setAnalyticDate] = useState({
    since: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    until: dayjs().subtract(1, 'day').format("YYYY-MM-DD"),
  });

  return (
    <AppContext.Provider
      value={{ homeDate, setHomeDate, analyticDate, setAnalyticDate }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, GlobalProvider };

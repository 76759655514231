import React, { useEffect, useState } from "react";
import { LineChart, PolarisVizProvider } from "@shopify/polaris-viz";
import { useDashboardChartsQuery } from "../../slices/spaceApi";
import dayjs from "dayjs";
import { formatNumber } from "../../utils/formatNumber";
import { formatReplace } from "../../utils/formatReplace";
import { useGlobalContext } from "../../context/globalContext";

const formatDateRangeName = (start, end) => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  // If the start and end dates are the same day
  if (startDate.isSame(endDate, "day")) {
    return startDate.format("D MMM YYYY");
  }

  // If the dates are in the same year
  if (startDate.year() === endDate.year()) {
    return `${startDate.format("D MMM")}–${endDate.format("D MMM YYYY")}`;
  }

  // If the dates are in different years
  return `${startDate.format("D MMM YYYY")}–${endDate.format("D MMM YYYY")}`;
};

function DashboardLineChart({ tabValue }) {
  const { homeDate } = useGlobalContext();

  const startDate = dayjs(homeDate?.since).format("YYYY-MM-DD");
  const endDate = dayjs(homeDate?.until).format("YYYY-MM-DD");

  // Define the start and end dates
  const start = dayjs(startDate).startOf("day");
  const end = dayjs(endDate).endOf("day");

  // // Define the start and end dates
  // const start = dayjs().subtract(1095, "day").startOf("day"); // Start of the day
  // const end = dayjs().subtract(1, "day").endOf("day"); // End of the day

  // Calculate the difference in days
  const diffInDays = end.diff(start, "day") + 1; // Add 1 to include both start and end dates

  // Calculate the previous start and end dates
  const prevStart = start.subtract(diffInDays, "day");
  const prevEnd = prevStart.add(diffInDays - 1, "day");

  const durationInDays = dayjs(endDate).diff(dayjs(startDate), "day");
  let intervalFormat;
  if (durationInDays <= 3) {
    intervalFormat = "%Y-%m-%d %H"; // Hourly
  } else if (durationInDays <= 90) {
    intervalFormat = "%Y-%m-%d"; // Daily
  } else {
    intervalFormat = "%Y-%m"; // Monthly
  }

  const { data, isLoading } = useDashboardChartsQuery({
    start: startDate,
    end: endDate,
  });

  const name = formatDateRangeName(start, end);
  const prevName = formatDateRangeName(prevStart, prevEnd);

  const formattedSessionsCurrentData =
    data?.sessionsChange?.sessionsChange?.map((item) => ({
      key: item._id,
      value: item.totalSessions,
    }));

  const formattedSessionsComparisonData =
    data?.sessionsChange?.sessionsChangeOverTime?.map((item) => ({
      key: item._id,
      value: item.totalSessions,
    }));

  const chartSessionData =
    formattedSessionsCurrentData && formattedSessionsComparisonData
      ? [
          {
            name,
            data: formattedSessionsCurrentData,
          },
          {
            isComparison: true,
            name: prevName,
            data: formattedSessionsComparisonData,
          },
        ]
      : [];

  const formattedSalesCurrentData = data?.salesChange?.salesChange?.map(
    (item) => ({
      key: item._id,
      value: item.totalSales?.toFixed(2),
    })
  );

  const formattedSalesComparisonData =
    data?.salesChange?.salesChangeOverTime?.map((item) => ({
      key: item._id,
      value: item.totalSales?.toFixed(2),
    }));

  const chartSalesData =
    formattedSalesCurrentData && formattedSalesComparisonData
      ? [
          {
            data: formattedSalesCurrentData,
            name,
          },
          {
            isComparison: true,
            name: prevName,
            data: formattedSalesComparisonData,
          },
        ]
      : [];

  const formattedOrdersCurrentData = data?.ordersChange?.ordersChange?.map(
    (item) => ({
      key: item._id,
      value: item.totalOrders,
    })
  );

  const formattedOrdersComparisonData =
    data?.ordersChange?.orderChangeOverTime?.map((item) => ({
      key: item._id,
      value: item.totalOrders,
    }));

  const chartOrdersData =
    formattedOrdersCurrentData && formattedOrdersComparisonData
      ? [
          {
            data: formattedOrdersCurrentData,
            name,
          },
          {
            isComparison: true,
            name: prevName,

            data: formattedOrdersComparisonData,
          },
        ]
      : [];

  const formattedConversionRateChangeCurrentData =
    data?.conversionRateChange?.conversionRateChange?.map((item) => ({
      key: item._id,
      value: item.conversionRate?.toFixed(2),
    }));

  const formattedConversionRateChangeComparisonData =
    data?.conversionRateChange?.conversionRateChangeOverTime?.map((item) => ({
      key: item._id,
      value: item.conversionRate?.toFixed(2),
    }));

  console.log(
    formattedConversionRateChangeComparisonData,
    "check?",
    formattedConversionRateChangeCurrentData
  );

  const chartConversionRateChangeData =
    formattedConversionRateChangeCurrentData &&
    formattedConversionRateChangeComparisonData
      ? [
          {
            data: formattedConversionRateChangeCurrentData,
            name,
          },
          {
            isComparison: true,
            name: prevName,
            data: formattedConversionRateChangeComparisonData,
          },
        ]
      : [];

  const chartData =
    tabValue === 1
      ? chartSessionData
      : tabValue === 2
      ? chartSalesData
      : tabValue === 3
      ? chartOrdersData
      : chartConversionRateChangeData;

  useEffect(() => {
    // setTimeout(() => {
    //   const lines = document.querySelectorAll('line[x2][stroke="#eeeeef"]');
    //   // Iterate over the selected elements
    //   lines.forEach((line) => {
    //     // Update the x2 attribute to 697.2
    //     line.setAttribute("x2", "697.2");
    //     // Get the current transform attribute value
    //     const transform = line.getAttribute("transform");
    //     // Extract the Y value from the transform attribute
    //     const yValue = transform.split(",")[1].trim().replace(")", "");
    //     // Update the transform attribute with the new x value
    //     line.setAttribute("transform", `translate(70.8,${yValue})`);
    //   });
    //   console.log(lines);
    //   const gs = document.querySelectorAll('g[transform*="translate(16"]');
    //   gs.forEach((g) => {
    //     // Get the current transform attribute value
    //     const transform = g.getAttribute("transform");
    //     // Update the x value of the transform attribute to 0
    //     const updatedTransform = transform.replace(
    //       /translate\(16,/,
    //       "translate(0,"
    //     );
    //     // Update the transform attribute with the new value
    //     g.setAttribute("transform", updatedTransform);
    //   });
    //   const button = document.querySelector(
    //     "button[data-color-vision-event-index='1']"
    //   );
    //   if (button) {
    //     const span = button.querySelector("span._IconContainer_97bxj_180");
    //     if (span) {
    //       span.innerHTML = `<svg class="_SVG_8b5v1_5" height="2px" viewBox="0 0 12 2" width="12px" xmlns="http://www.w3.org/2000/svg"><g fill="rgba(10, 151, 213, 0.5)"><circle cx="1" cy="1" r="1"></circle><circle cx="6" cy="1" r="1"></circle><circle cx="11" cy="1" r="1"></circle></g></svg>`;
    //     }
    //   }
    // },1);
  }, [chartData]);

  return (
    <>
      {chartData?.length >= 1 && (
        <PolarisVizProvider themes={{}}>
          <LineChart
            theme="default"
            data={chartData}
            yAxisOptions={{
              hide: false,
              labelFormatter: (value) => {
                return `${tabValue === 2 ? "PKR" : ""}${
                  value ? formatNumber(value) : 0
                }`;
              },
            }}
            xAxisOptions={{
              hide: false,
              labelFormatter: (value) => {
                return intervalFormat === "%Y-%m-%d %H"
                  ? dayjs(value).format("hh:mm A")
                  : intervalFormat === "%Y-%m-%d"
                  ? dayjs(value).format("DD MMM")
                  : dayjs(value).format("MMM YYYY");
              },
            }}
            tooltipOptions={{
              renderTooltipContent: (data) => (
                <div
                  class="Polaris-Box"
                  style={{
                    "--pc-box-background": "var(--p-color-bg-surface)",
                    "--pc-box-border-radius": "var(--p-border-radius-200)",
                    "--pc-box-min-width": "150px",
                    "--pc-box-padding-block-start-xs": "var(--p-space-200)",
                    "--pc-box-padding-block-end-xs": "var(--p-space-200)",
                    "--pc-box-padding-inline-start-xs": "var(--p-space-200)",
                    "--pc-box-padding-inline-end-xs": "var(--p-space-200)",
                    "--pc-box-shadow": "var(--p-shadow-300)",
                  }}
                >
                  <div
                    class="Polaris-BlockStack"
                    style={{
                      "--pc-block-stack-order": "column",
                      "--pc-block-stack-gap-xs": "var(--p-space-100)",
                    }}
                  >
                    <div
                      class="Polaris-BlockStack"
                      style={{
                        "--pc-block-stack-order": "column",
                        "--pc-block-stack-gap-xs": "var(--p-space-200)",
                      }}
                    >
                      <div class="Polaris-Box" style={{ opacity: 1 }}>
                        <div
                          class="Polaris-InlineStack"
                          style={{
                            "--pc-inline-stack-align": "space-between",
                            "--pc-inline-stack-block-align": "center",
                            "--pc-inline-stack-wrap": "nowrap",
                            "--pc-inline-stack-gap-xs": "var(--p-space-300)",
                            "--pc-inline-stack-flex-direction-xs": "row",
                          }}
                        >
                          <div
                            class="Polaris-InlineStack"
                            s
                            style={{
                              "--pc-inline-stack-block-align": "center",
                              "--pc-inline-stack-wrap": "nowrap",
                              "--pc-inline-stack-gap-xs": "var(--p-space-100)",
                              "--pc-inline-stack-flex-direction-xs": "row",
                            }}
                          >
                            <div class="Polaris-Box">
                              <span
                                class="_Container_8b5v1_1"
                                style={{ height: "2px", width: "12px" }}
                              >
                                <svg
                                  class="_SVG_8b5v1_5"
                                  height="2px"
                                  viewBox="0 0 12 2"
                                  width="12px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <defs>
                                    <linearGradient
                                      id="linePreviewGradient-40"
                                      x1="0%"
                                      x2="100%"
                                      y1="0%"
                                      y2="0%"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop
                                        offset="0%"
                                        stop-color="#0A97D5"
                                        stop-opacity="1"
                                      ></stop>
                                      <stop
                                        offset="100%"
                                        stop-color="#50C3F7"
                                        stop-opacity="1"
                                      ></stop>
                                    </linearGradient>
                                  </defs>
                                  <path
                                    d="M1 1 H12"
                                    stroke="url(#linePreviewGradient-40)"
                                    stroke-linejoin="round"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                    stroke-dasharray="none"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                              {intervalFormat === "%Y-%m-%d %H"
                                ? dayjs(
                                    data?.dataSeries?.[0]?.data?.[
                                      data?.activeIndex
                                    ]?.key
                                  ).format("DD MMM, hh:mm A")
                                : intervalFormat === "%Y-%m-%d"
                                ? dayjs(
                                    data?.dataSeries?.[0]?.data?.[
                                      data?.activeIndex
                                    ]?.key
                                  ).format("DD-MMM-YYYY")
                                : dayjs(
                                    data?.dataSeries?.[0]?.data?.[
                                      data?.activeIndex
                                    ]?.key
                                  ).format("MMM YYYY")}
                            </p>
                          </div>
                          <div
                            class="Polaris-InlineStack"
                            style={{
                              "--pc-inline-stack-block-align": "center",
                              "--pc-inline-stack-wrap": "nowrap",
                              "--pc-inline-stack-gap-xs": "var(--p-space-200)",
                              "--pc-inline-stack-flex-direction-xs": "row",
                            }}
                          >
                            <div
                              class="_Value_18pd5_1"
                              style={{ marginLeft: "0px" }}
                            >
                              <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                {tabValue === 2
                                  ? data?.dataSeries?.[0]?.data?.[
                                      data?.activeIndex
                                    ]?.value?.replace(formatReplace, ",")
                                  : data?.dataSeries?.[0]?.data?.[
                                      data?.activeIndex
                                    ]?.value?.toLocaleString()}
                              </p>
                            </div>
                            <div class="" aria-hidden="false">
                              <svg
                                viewBox="0 0 11 16"
                                height="16"
                                width="11"
                                role="img"
                                class="_SVG_1ou8g_150"
                                tabindex="-1"
                              >
                                <title>No change</title>
                                <path
                                  d="M0.519531 1.79395H12.0039V0.249023H0.519531V1.79395Z"
                                  fill="#8A8A8A"
                                  transform="translate(0, 7)"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Polaris-Box" style={{ opacity: 1 }}>
                        <div
                          class="Polaris-InlineStack"
                          style={{
                            "--pc-inline-stack-align": "space-between",
                            "--pc-inline-stack-block-align": "center",
                            "--pc-inline-stack-wrap": "nowrap",
                            "--pc-inline-stack-gap-xs": "var(--p-space-300)",
                            "--pc-inline-stack-flex-direction-xs": "row",
                          }}
                        >
                          <div
                            class="Polaris-InlineStack"
                            style={{
                              "--pc-inline-stack-block-align": "center",
                              "--pc-inline-stack-wrap": "nowrap",
                              "--pc-inline-stack-gap-xs": "var(--p-space-100)",
                              "--pc-inline-stack-flex-direction-xs": "row",
                            }}
                          >
                            <div class="Polaris-Box">
                              <span
                                class="_Container_8b5v1_1"
                                style={{ height: "2px", width: "12px" }}
                              >
                                <svg
                                  class="_SVG_8b5v1_5"
                                  height="2px"
                                  viewBox="0 0 12 2"
                                  width="12px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="rgba(10, 151, 213, 0.5)">
                                    <circle cx="1" cy="1" r="1"></circle>
                                    <circle cx="6" cy="1" r="1"></circle>
                                    <circle cx="11" cy="1" r="1"></circle>
                                  </g>
                                </svg>
                              </span>
                            </div>
                            <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--truncate">
                              {intervalFormat === "%Y-%m-%d %H"
                                ? dayjs(
                                    data?.dataSeries?.[1]?.data?.[
                                      data?.activeIndex
                                    ]?.key
                                  ).format("DD MMM, hh:mm A")
                                : intervalFormat === "%Y-%m-%d"
                                ? dayjs(
                                    data?.dataSeries?.[1]?.data?.[
                                      data?.activeIndex
                                    ]?.key
                                  ).format("DD-MMM-YYYY")
                                : dayjs(
                                    data?.dataSeries?.[1]?.data?.[
                                      data?.activeIndex
                                    ]?.key
                                  ).format("MMM YYYY")}
                            </p>
                          </div>
                          <div
                            class="Polaris-InlineStack"
                            style={{
                              "--pc-inline-stack-block-align": "center",
                              "--pc-inline-stack-wrap": "nowrap",
                              "--pc-inline-stack-gap-xs": "var(--p-space-200)",
                              "--pc-inline-stack-flex-direction-xs": "row",
                            }}
                          >
                            <div
                              class="_Value_18pd5_1"
                              style={{ marginLeft: "0px" }}
                            >
                              <p class="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium Polaris-Text--block Polaris-Text--end Polaris-Text--truncate">
                                {data?.dataSeries?.[1]?.data?.[
                                  data?.activeIndex
                                ]?.value?.toLocaleString()}
                              </p>
                            </div>
                            <div
                              class="_Hidden_18pd5_5"
                              style={{ visibility: "hidden" }}
                              aria-hidden="true"
                            >
                              <svg
                                viewBox="0 0 11 16"
                                height="16"
                                width="11"
                                role="img"
                                class="_SVG_1ou8g_150"
                                tabindex="-1"
                              >
                                <title>No change</title>
                                <path
                                  d="M0.519531 1.79395H12.0039V0.249023H0.519531V1.79395Z"
                                  fill="#8A8A8A"
                                  transform="translate(0, 7)"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </PolarisVizProvider>
      )}
    </>
  );
}

export default DashboardLineChart;

import { configureStore } from "@reduxjs/toolkit";
import { spaceApi } from "../slices/spaceApi";

const store = configureStore({
  reducer: {
    [spaceApi.reducerPath]: spaceApi.reducer,
  },
  middleware: (middleware) => middleware().concat(spaceApi.middleware),
});

export default store;

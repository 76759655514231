import React, { useEffect } from "react";
import DashboardCards from "../components/home/dashboardCards";

import DashboardAnalytics from "../components/home/dashboardAnalytics";
import "../styles/render.css";
import { Helmet } from "react-helmet";


function Home() {
  useEffect(() => {
    const pageElement = document.querySelector(".Polaris-Page");
    console.log(pageElement, "test?");
  }, []);

  return (
    <>
      <Helmet>
        <title>essentailss · Home · Shopify</title>
      </Helmet>

      <div className="Polaris-Page">
        {/* Dashboard Analytics */}
        <DashboardAnalytics />

        {/* Dashboard Card */}
        <DashboardCards />
      </div>
    </>
  );
}
export default Home;
